import React from "react";
import { ethers } from "ethers";
import TokenArtifact from "../contracts/Token.json";
import contractAddress from "../contracts/contract-address.json";
import PoLottoExchange from "../contracts/PoLottoExchangeABI.json";
import TDTokenABI from "../contracts/TDToken.json";
import CHAIN_ID from "../assets/chainIds.json";
import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";
import ExchangeComponent from "./ExchangeComponent";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
import { TopMenu } from "./TopMenu";
import { Row, Col, Avatar, Menu, message, Modal, Spin } from "antd";
import { getChainNameById, isSupportChain } from "../utils/util";
import { mianChianId } from "../utils/config"
import { getAddress, removeAddress } from "../utils/address";
import RebaseTime from "../components/Rebase/RebaseTime"
import { formatNumberFixed } from "../utils/util";


const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export class Dapp extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      tokenData: undefined,
      selectedAddress: undefined,
      balances: {},
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      exchangeRate: undefined,
      tableData: [],
      chainId: undefined,
      isModalOpen: false,
      depositButton: true,
      bookButton: true,
      spinning: false,
      tokenNameData: {
        TDToken: 'TD',
        USDCToken: 'USDC'
      }
      // ddDecimals: 6
    };
    this.state = {
      ...this.initialState,
      queueDataUpdated: false,
    };
  }

  render() {
    if (window.ethereum === undefined) {
      return (
        <>
          <TopMenu/>
          <NoWalletDetected />
        </>
      
      );
    }

    if (
      !this.state.selectedAddress &&
      !localStorage.getItem("selectedAddress")
    ) {
      return (
        <>
          <TopMenu connectWallet={() => this._connectWallet()}/>
          <ConnectWallet
            connectWallet={() => this._connectWallet()}
            networkError={this.state.networkError}
            dismiss={() => this._dismissNetworkError()}
          />
        </>
        
      );
    }

    // if (!this.state.tokenData || Object.keys(this.state.balances).length === 0) {
    //   return <Loading />;
    // }

    const { tokenNameData, balances } = this.state;
    return (
      <div style={{ backgroundColor: '#e2ccba', minHeight: '100vh'}}>
        <TopMenu isConnect={true} disConnectAccount={() => this._disConnectAccount()}/>
        <div
          className="container p-4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: 'flex', marginBottom: 15, justifyContent: 'space-around', width: '100%', maxWidth: 600 }}>
              {Object.keys(balances).map((tokenName) => (
                  <div key={tokenName} style={{ textAlign: "center" }}>
                    <h6>{formatNumberFixed(Number(balances[tokenName]))}</h6>
                    <h6>
                      {tokenNameData[tokenName]}
                    </h6>
                  </div>
                ))}
          </div>
          <div className="row" style={{ marginBottom: 5 }}>
            <div className="col-12">
              {/* 
              Sending a transaction isn't an immediate action. You have to wait
              for it to be mined.
              If we are waiting for one, we show a message here.
            */}
              {this.state.txBeingSent && (
                <WaitingForTransactionMessage txHash={this.state.txBeingSent} />
              )}

              {/* 
              Sending a transaction can fail in multiple ways. 
              If that happened, we show a message here.
            */}
              {this.state.transactionError && (
                <TransactionErrorMessage
                  message={this._getRpcErrorMessage(
                    this.state.transactionError
                  )}
                  dismiss={() => this._dismissTransactionError()}
                />
              )}
            </div>
          </div>

          <ExchangeComponent
            exchangeTokens={(amount, status) =>
              this._transferTokens(amount, status)
            }
            bridgeTokens={(amount) => this._bridgeTokens(amount)}
            updateBalances={() => this._updateBalances()}
            // exchangeRate={this.state.exchangeRate}
            balances={this.state.balances}
            chainId={this.state.chainId}
            depositButton={this.state.depositButton}
            bookButton={this.state.bookButton}
            queueDataUpdated={this.state.queueDataUpdated}
            resetQueueDataUpdated={() =>
              this.setState({ queueDataUpdated: false })
            }
            updateQueueData={() => this.updateQueueData()}
          />

          {/* <div style={{marginTop: '40px'}}>
            last rebase time: 2024-05-29 10: 20: 22
          </div> */}
          <RebaseTime />

          <Modal
            title="PRE-LAUNCH TEST PHASE"
            closeIcon={false}
            open={this.state.isModalOpen}
            onOk={()=>this.handleOk()}
            className="custom-modal"
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <OkBtn />
              </>
            )}
          >
            <p style={{textIndent: '2em'}}>Please note that the protocol is not yet open to the general public. The contracts may be redeployed at any time without advance notice. There is no guarantee that funds are safe. Please do not deposit money that you cannot afford to lose. By clicking OK you acknowledge this warning.</p>
            <p style={{textIndent: '2em'}}>请注意，该协议尚未向公众开放。合同可随时重新部署，无需事先通知。不能保证资金是安全的。请不要存你负担不起的钱。通过点击OK表示认可此警告。</p>
          </Modal>

          <Spin spinning={this.state.spinning} fullscreen />
        </div>
      </div>
    );
  }

  async handleOk(){
    const chainId = await window.ethereum.request({
      method: "eth_chainId",
      params: [],
    });
    this.setState({
      isModalOpen : false,
      chainId: chainId
    }, () => {
      if (localStorage.getItem("selectedAddress") && isSupportChain(this.state.chainId) ) {
        this.setState({spinning: true})
        this._connectWallet();
      }
    })
    
  }

  componentDidMount() {
    console.log("componentDidMount...");
    if (window.ethereum) {
      this.handleOk()
      window.ethereum.on("chainChanged", (chainId) => {
        console.log("chainId:", chainId);
        window.location.reload();
        // polygon testnet 0x13881
        // bsc testnet 0x61
        //0x89 polygan mainnet
        // if (isSupportChain(chainId)) {
        //   this._initialize(localStorage.getItem("selectedAddress"));
        // }else{
        //   Modal.error({
        //     title: 'This is an error message',
        //     content: 'This chain is unsupported, you need to switch supported chain to use this project',
        //   });
        // }
        // this.setState({ chainId: chainId });
      }); 
    }
    // if (localStorage.getItem("selectedAddress")) {
    //   this._connectWallet();
    // }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount..........')
    this._stopPollingData();
    window.ethereum.removeListener("accountsChanged", ([newAddress]) => {
      this._stopPollingData();
      if (newAddress === undefined) {
        return this._resetState();
      }
      this._initialize(newAddress);
    })
    window.ethereum.removeListener("chainChanged", (chainId) => {
      console.log("chainId:", chainId);
      window.location.reload();
      // window.location.reload()
      // if (isSupportChain(chainId)) {
      //   this._initialize(localStorage.getItem("selectedAddress"));
      // }else{
      //   Modal.error({
      //     title: 'This is an error message',
      //     content: 'This chain is unsupported, you need to switch supported chain to use this project',
      //   });
      // }
      // this.setState({ chainId: chainId });
    })
  }

  _disConnectAccount(){
    removeAddress()
    this.setState({selectedAddress: undefined})
    window.ethereum.removeListener("accountsChanged", ([newAddress]) => {
      this._stopPollingData();
      if (newAddress === undefined) {
        return this._resetState();
      }
      this._initialize(newAddress);
    })
    window.ethereum.removeListener("chainChanged", (chainId) => {
      if (isSupportChain(chainId)) {
        this._initialize(localStorage.getItem("selectedAddress"));
      }else{
        Modal.error({
          title: 'This is an error message',
          content: 'This chain is unsupported, you need to switch supported chain to use this project',
        });
      }
      this.setState({ chainId: chainId });
    })
  }

  async _connectWallet() {
    console.log("dddd", window.ethereum);
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    this._initialize(selectedAddress);
    window.ethereum.on("accountsChanged", ([newAddress]) => {
      this._stopPollingData();
      if (newAddress === undefined) {
        return this._resetState();
      }
      this._initialize(newAddress);
    });
  }

  async _initialize(userAddress) {
    localStorage.setItem("selectedAddress", userAddress);
    this.setState(
      {
        selectedAddress: userAddress,
      },
      async () => {
        await this._initializeEthers();
        // await this._getTokenData();
        // this._getExchangeRate();
        this._startPollingData();
      }
    );
  }

  async _initializeEthers() {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    // polygon testnet 0x13881
    // bsc testnet 0x61
    //0x89 polygan mainnet
    //0xa86a avalanche

    const chainId = await window.ethereum.request({
      method: "eth_chainId",
      params: [],
    });
    this.setState({ chainId: chainId });
    let chainName = undefined;
    chainName = getChainNameById(chainId)
    // if (chainId == "0x61") {
    //   chainName = "bsc";
    // } else if (chainId == "0x89") {
    //   //0x13881
    //   chainName = "polygon";
    // } else if (chainId == "0xa86a") {
    //   chainName = "avalanche";
    // } else {
    //   chainName = undefined;
    // }

    this._tokens = {};
    // if (chainId === mianChianId) {
    //   this._tokens["TDToken"] = new ethers.Contract(
    //     contractAddress.TDToken[chainName],
    //     TokenArtifact,
    //     this._provider.getSigner(0)
    //   ); 
    // }
    this._tokens["TDToken"] = new ethers.Contract(
      contractAddress.TDToken[chainName],
      TokenArtifact,
      this._provider.getSigner(0)
    ); 
    this._tokens["USDCToken"] = new ethers.Contract(
      contractAddress.USDCToken[chainName],
      TokenArtifact,
      this._provider.getSigner(0)
    );
    // for (const token of contractAddress.Token) {
    //   this._tokens[token.name] = new ethers.Contract(
    //     token.address,
    //     TokenArtifact,
    //     this._provider.getSigner(0)
    //   );
    // }
    if (chainId == mianChianId) {
      this._poLotto = new ethers.Contract(
        contractAddress.Contract[0].address,
        PoLottoExchange,
        this._provider.getSigner(0)
      );
    }
  }

  _startPollingData() {
    // this._pollDataInterval = setInterval(() => this._updateBalances(), 1000);
    this._updateBalances();
  }

  _stopPollingData() {
    // clearInterval(this._pollDataInterval);
    // this._pollDataInterval = undefined;
  }

  async _getTokenData() {
    const tokenData = {};
    for (const token of contractAddress.Token) {
      // if (mianChianId !== this.state.chainId && token.name === "TDToken") {
      //   continue;
      // }
      const name = await this._tokens[token.name].name();
      const symbol = await this._tokens[token.name].symbol();
      tokenData[token.name] = { name, symbol };
    }
    this.setState({ tokenData: tokenData });
  }
  async _getExchangeRate() {
    const exchangeRate = await this._poLotto.DDExchangeRate();
    // const exchangeRate = 0.6
    console.log("112222", exchangeRate);
    this.setState({ exchangeRate: exchangeRate.toString() });
  }

  async _updateBalances() {
    const { utils } = require("ethers");
    const balances = {};
    for (const token of contractAddress.Token) {
      // if (mianChianId !== this.state.chainId && token.name === "TDToken") {
      //   continue;
      // }
      console.log("111", await this._tokens[token.name].name());
      const balance = await this._tokens[token.name]
        .balanceOf(
          // this.state.selectedAddress
          localStorage.getItem("selectedAddress")
        )
        .catch((err) => console.log("112223332", err));
      // console.log('112223332', err)
      const wei = await this._tokens[token.name].decimals();
      console.log(wei, balance)
      const balanceWithoutDecimals = utils.formatUnits(
        balance ? balance : 0,
        wei
      );
      balances[token.name] = balanceWithoutDecimals;
    }
    console.log("112223333", balances);
    this.setState({ balances }, () => {
      this.setState({spinning: false})
    });
  }

  async _bridgeTokens(amount) {
    this._TDtoken = new ethers.Contract(
      contractAddress.TDToken[1].address,
      TDTokenABI,
      this._provider.getSigner(0)
    );
    const qty = ethers.utils.parseEther(amount);
    console.log("l222i", this._TDtoken);
    //打包address字段和交易发起者地址
    const coder = ethers.utils.defaultAbiCoder;
    const toAddressBytes = coder.encode(
      ["address"],
      [localStorage.getItem("selectedAddress")]
    );

    const zero = ethers.constants.AddressZero;

    // 获取远程chainID
    const remoteChainId = CHAIN_ID["polygon_testnet"];
    const adapterParams = ethers.utils.solidityPack(
      ["uint16", "uint256"],
      [1, 200000]
    );
    const fees = await this._TDtoken.estimateSendFee(
      remoteChainId,
      toAddressBytes,
      qty,
      false,
      adapterParams
    );
    console.log("li", fees);
    const sendTx = await this._TDtoken.sendFrom(
      localStorage.getItem("selectedAddress"),
      remoteChainId,
      toAddressBytes,
      qty,
      {
        refundAddress: localStorage.getItem("selectedAddress"),
        zroPaymentAddress: zero,
        adapterParams,
      },
      {
        value: fees[0],
      }
    );

    const tx = await sendTx.wait();
    console.log("li", tx);
    message.success("tx:", tx.hash);
  }

  async _transferTokens(amount, status) {
    // console.log(amount, ethers.utils.parseUnits(amount, 6).toString())
    if (status === "Exchange") {
      try {
        this.setState({depositButton: false})
        const token = this._tokens.USDCToken;
        const allowance = await token.allowance(getAddress(), contractAddress.Contract[0].address)
        const allowanceAmount = ethers.utils.formatUnits(allowance, 6)
        console.log('allowanceAmount', allowanceAmount)
        if (allowanceAmount < amount) {
          const approveTx = await token.approve(
            contractAddress.Contract[0].address,
            ethers.utils.parseUnits(amount, 6)
          );
          const tx = await approveTx.wait();
          console.log("等待", tx); 
        }
        const depositTx = await this._poLotto.deposit(
          ethers.utils.parseUnits(amount, 6)
        );
        this.setState({ txBeingSent: depositTx.hash });
        const receipt = await depositTx.wait();
        console.log("转账", receipt);
        if (receipt.status === 0) {
          throw new Error("Transaction failed");
        }
        await this._updateBalances();
      } catch (error) {
        if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
          return;
        }
        console.error(error);
        this.setState({ transactionError: error });
      } finally {
        this.setState({ 
          txBeingSent: undefined,
          depositButton: true
         });
      }
    } else {
      try {
        this.setState({bookButton: false})
        // const token = this._tokens.TDToken;
        // const allowance = await token.allowance(getAddress(), contractAddress.Contract[0].address)
        // const allowanceAmount = ethers.utils.formatUnits(allowance)
        // console.log(allowanceAmount, 'allowanceAmount')
        // if (allowanceAmount < amount) {
        //   const approveTx = await token.approve(
        //     contractAddress.Contract[0].address,
        //     ethers.utils.parseUnits(amount, this.state.ddDecimals)
        //   );
        //   const tx = await approveTx.wait();
        //   console.log("等待", tx); 
        // }
        const queueWithdrawTx = await this._poLotto.queueWithdraw(
          ethers.utils.parseUnits(amount, this.state.ddDecimals)
        );
        this.setState({ txBeingSent: queueWithdrawTx.hash });
        const receipt = await queueWithdrawTx.wait();
        if (receipt.status === 0) {
          throw new Error("Transaction failed");
        }
        await this._updateBalances();
        this.setState({ txBeingSent: undefined, queueDataUpdated: true });
      } catch (error) {
        if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
          return;
        }
        console.error(error);
        this.setState({ transactionError: error });
      } finally {
        this.setState({ 
          txBeingSent: undefined,
          bookButton: true
         });
      }
    }
  }

  async updateQueueData() {
    this.setState({ queueDataUpdated: true });
  }

  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }
    return error.message;
  }

  _resetState() {
    this.setState(this.initialState);
  }
}

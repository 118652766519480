
import { Tabs, Collapse } from "antd"
import { useTranslation } from "react-i18next"
import feature1 from "../../images/feature1.jpg"
import feature2 from "../../images/feature2.png"
import feature3 from "../../images/feature3.png"

export default function KeyFeature(){
    const {t} = useTranslation()
    const featureItems =[
        {
            key: 1,
            label: t('full-collateralization'),
            children: (
                <div className="feature-content contianer">
                    <div className="row">
                        <ul className="feature-list col-lg-8 align-items-center">
                            {/* <div className="list-item">{t('full-collateralization-first')}</div>  */}
                            <li className="list-item">{t('full-collateralization-first')}</li> 
                            <li className="list-item">{t('full-collateralization-second')}</li> 
                        {/* <li className="list-item">{t('full-collateralization-third')}</li>  */}
                        </ul>
                        <div className="col-lg-4 d-flex align-items-center justify-content-center">
                            <img src={feature1} alt="dd" className="feature-img "/>
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 2,
            label: t('rebase'),
            children: (
                <div className="feature-content contianer">
                    <div className="row">
                        <ul className="feature-list col-lg-8 d-flex align-items-center">
                            <div className="list-item">{t('rebase-first')}</div> 
                        {/* <li className="list-item">{t('rebase-first')}</li>  */}
                        {/* <li className="list-item">{t('rebase-second')}</li>  */}
                        </ul>
                        <div className="col-lg-4 d-flex align-items-center justify-content-center">
                            <img src={feature2} alt="dd" className="feature-img "/>
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 3,
            label: t('passive-yield'),
            children: (
                <div className="feature-content contianer">
                    <div className="row">
                        <ul className="feature-list col-lg-8 d-flex align-items-center">
                            <div className="list-item">{t('passive-yield-first')}</div>
                        {/* <li className="list-item">{t('passive-yield-first')}</li>  */}
                        {/* <li className="list-item">{t('passive-yield-second')}</li> 
                        <li className="list-item">{t('passive-yield-third')}</li>  */}
                        </ul>
                        <div className="col-lg-4 d-flex align-items-center justify-content-center">
                            <img src={feature3} alt="dd" className="feature-img "/>
                        </div>
                    </div>
                </div>
            )
        },
        // {
        //     key: 4,
        //     label: t('usablity'),
        //     children: (
        //         <div className="feature-content contianer">
        //             <div className="row">
        //                 <ul className="feature-list col-lg-8 d-flex align-items-center">
        //                     <div className="list-item">{t('usablity-first')}</div>
        //                 {/* <li className="list-item">{t('usablity-first')}</li>  */}
        //                 {/* <li className="list-item">{t('usablity-second')}</li> 
        //                 <li className="list-item">{t('usablity-third')}</li>  */}
        //                 </ul>
        //                 <div className="col-lg-4 d-flex align-items-center justify-content-center">
        //                     <img src={feature1} alt="dd" className="feature-img "/>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // },
    ]


    return (
        <div className="main-container">
            <div className="key-feature container">
                <div className="title">{t('key-feature')}</div>
                <div style={{marginBottom: '20px', color: '#7f8585'}}>
                    {t('feature-text')}
                </div>
                <div className="feature-card">
                    <Collapse defaultActiveKey={['1']} ghost items={featureItems} />
                    {/* <Tabs 
                        type="card"
                        items={featureItems}
                    /> */}
                </div>
            </div>
        </div>
    )
}
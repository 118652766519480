import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getAddress, hasAddress } from '../utils/address';
import contractAddress from "../contracts/contract-address.json"
import TDTokenABI from "../contracts/TDToken.json";
import useContract from './useContract';
import { Spin } from 'antd';
import { ethers } from "ethers";
import useChainId from './useChainId';
import { getChainNameById } from '../utils/util';
import { mianChianId } from '../utils/config';

const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
    const [balance, setBalance] = useState(0.0);
    const [usdcBalance, setUsdcBalance] = useState(0.0);
    const [loading, setLoading] = useState(false);
    const chainId = useChainId();

    const { callMethod: callTdMethod, contract: tdContract } = useContract(contractAddress.TDToken[getChainNameById(chainId)], TDTokenABI);
    const { callMethod: callUsdcMethod, contract: usdcContract } = useContract(contractAddress.USDCToken[getChainNameById(chainId)], TDTokenABI);

    const updateBalance = useCallback(async () => {
        try {
            if (tdContract) {
                setLoading(true);
                const balance = await callTdMethod("balanceOf", getAddress());
                const units = await callTdMethod("decimals");
                const balanceWithoutDecimals = ethers.utils.formatUnits(balance ? balance : 0.0, units);
                setBalance(balanceWithoutDecimals);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }

    }, [tdContract, callTdMethod]);


    const updateUsdcBalance = useCallback(async () => {
        try {
            if (usdcContract) {
                const balance = await callUsdcMethod("balanceOf", getAddress());
                const units = await callUsdcMethod("decimals");
                const balanceWithoutDecimals = ethers.utils.formatUnits(balance ? balance : 0.0, units);
                setUsdcBalance(balanceWithoutDecimals);
            }
        } catch (error) {
            console.log(error);
        }
    }, [usdcContract, callUsdcMethod])


    useEffect(() => {
        // if (chainId === mianChianId) {
        //     updateBalance();
        // }
        updateBalance();
    }, [updateBalance]);

    useEffect(() => {
        updateUsdcBalance();
    }, [updateUsdcBalance]);

    return (
        <BalanceContext.Provider value={{ balance, usdcBalance, updateBalance, updateUsdcBalance }}>
        {children}
        <Spin spinning={loading} fullscreen />
        </BalanceContext.Provider>
    );
}

export const useBalance = () => useContext(BalanceContext);

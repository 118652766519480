 // 封装本地存储的操作
const ADDRESS_KEY = 'selectedAddress'

// 获取 address
export function getAddress () {
  return localStorage.getItem(ADDRESS_KEY)
}

// 本地存储 address
export function setAddress (address) {
  localStorage.setItem(ADDRESS_KEY, address)
}

// 删除 address
export function removeAddress () {
  localStorage.removeItem(ADDRESS_KEY)
}

// 判断有无 address
export function hasAddress () {
  return !!getAddress()
}


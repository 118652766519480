import { useState } from "react"
import { Select, Input, Button, Space, message } from "antd"
import { useTranslation } from "react-i18next"
import { getChainNameById } from "../../utils/util";
import { getAddress } from "../../utils/address";
import { ethers } from "ethers";
import { LoadingModal } from "../loading/LoadingModal";
import { useBalance } from "../../hooks/useBalance";
import { formatNumberFixed } from "../../utils/util";

import contractAddress from "../../contracts/contract-address.json";
import TDTokenABI from "../../contracts/TDToken.json";
import CHAIN_ID from "../../assets/chainIds.json";
import polyImage from "../../images/polygon.png"
import baseImage from "../../images/base.png"
import opImage from "../../images/optimism.png"
import arbImage from "../../images/arbitrum.png"
import "../../bridgeCom.scss"
export default function BridgeTD({ chainId}){

    const {t} = useTranslation()
    const [bridgeFromValue, setBridgeFromValue] = useState('0x2105')
    const [bridgeToValue, setBridgeToValue] = useState('0xa')
    const [bridgeTdAmount, setBridgeTdAmount] = useState("0")
    const [estimateFee, setEstimateFee] = useState("0")
    const [loadingStatus, setLoadingStatus] = useState(false)
    const {balance, updateBalance } = useBalance()

    const bridgeOptions = [
        {
          value: '0x2105',
          label: (
            <Space style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <img src={baseImage} alt="" style={{
                width: 16,
                height: 16
              }}/>
              <span>Base</span>
            </Space>
          )
        },
        {
          value: '0xa',
          label: (
            <Space>
              <img src={opImage} alt="" style={{
                width: 16,
                height: 16
              }}/>
              <span>Optimism</span>
            </Space>
          )
        },
        {
          value: '0xa4b1',
          label: (
            <Space>
              <img src={arbImage} alt="" style={{
                width: 16,
                height: 16
              }}/>
              <span>Arbitrum</span>
            </Space>
          )
        },
      ]

    const handleBridgeFromChange = (value) => {
        if (value == bridgeToValue) {
            setBridgeToValue(bridgeFromValue)
            setBridgeFromValue(value)
          }else{
            setBridgeFromValue(value)
          }
    }

    const handleBridgeToChange = (value) => {
        if (value == bridgeFromValue) {
            setBridgeFromValue(bridgeToValue)
            setBridgeToValue(value)
            
          }else{
            setBridgeToValue(value)
          }
    }

    const setMaxBridgeValue = () => {
      if (bridgeFromValue === chainId) {
        estimateGasFee(balance)
      }
      setBridgeTdAmount(balance)
    }

    const estimateGasFee = async (amount) => {
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const _wTDtoken = new ethers.Contract(
            contractAddress.TDToken[getChainNameById(bridgeFromValue)],
            TDTokenABI,
            _provider.getSigner(0)
        )
        const qty = ethers.utils.parseEther(amount)
        console.log('l222i', _wTDtoken)
        //打包address字段和交易发起者地址
        const coder =  ethers.utils.defaultAbiCoder
        const toAddressBytes = coder.encode(["address"], [getAddress()])
        
    
        // 获取远程chainID
        const remoteChainId = CHAIN_ID[getChainNameById(bridgeToValue)]
        const adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 200000])
        const fees = await _wTDtoken.estimateSendFee(remoteChainId, toAddressBytes, qty, false, adapterParams).catch(err => {
          console.log(err)
        })
        console.log('li', fees)
        console.log('li222', remoteChainId)
        console.log('li5555', qty.toString())
        console.log('li3333', ethers.utils.formatEther(fees[0]))
        setEstimateFee(ethers.utils.formatEther(fees[0]))
    }

    const switchChain = async (chainId) => {
        await window.ethereum.request({
          "method": "wallet_switchEthereumChain",
          "params": [
            {
              "chainId": chainId
            }
          ]
        }).catch(err => console.log(err));
    }

    const formatNumber = (value) => {
        value += '';
        const list = value.split('.');
        const prefix = list[0].charAt(0) === '-' ? '-' : '';
        let num = prefix ? list[0].slice(1) : list[0];
        let result = '';
        while (num.length > 3) {
          result = `,${num.slice(-3)}${result}`;
          num = num.slice(0, num.length - 3);
        }
        if (num) {
          result = num + result;
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
    }

    const handleBridgeInputChange = (e) => {
        
        let value = e.target.value.replace(/[^\-?\d.]/g,'')
        if (!e.target.value) {
          value = 0
        }
        if (value > balance) {
          value = balance
        }
        if (bridgeFromValue === chainId) {
          estimateGasFee(formatNumber(value)) 
        }
        setBridgeTdAmount(value)
    }

    const hadnleBridgeClick = async() => {
        setLoadingStatus(true)
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const _wTDtoken = new ethers.Contract(
          contractAddress.TDToken[getChainNameById(bridgeFromValue)],
          TDTokenABI,
              _provider.getSigner(0)
          )
        const qty = ethers.utils.parseEther(formatNumber(bridgeTdAmount))
        console.log('l222i', _wTDtoken)
        //打包address字段和交易发起者地址
        const coder =  ethers.utils.defaultAbiCoder
        const toAddressBytes = ethers.utils.solidityPack(["address"], [getAddress()])
        
        const zero = ethers.constants.AddressZero
        // 获取远程chainID
        const remoteChainId = CHAIN_ID[getChainNameById(bridgeToValue)]
        const adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 400000])
        const fees = await _wTDtoken.estimateSendFee(remoteChainId, toAddressBytes, qty, false, adapterParams).catch(err => console.log(err))
        console.log('li', fees)
        const sendTx = await _wTDtoken.send(
            getAddress(),
            remoteChainId,
            toAddressBytes,
            qty,
            getAddress(),
            zero,
            '0x',
            {
                  value: fees[0]
            }
            // {
            //     refundAddress: getAddress(),
            //     zroPaymentAddress: zero,
            //     adapterParams,
            // },
            // {
            //     value: fees[0]
            // }
        ).catch(err => {
        console.log(err);
            message.error(err.message) 
        })
        
        if (sendTx) {
            const tx = await sendTx.wait().catch(err => console.log(err))
            console.log('li', tx)
            updateBalance()
            message.success('tx:'+ tx.transactionHash + '.This transcation is completed. Your will receive within 24 hours') 
        }
        setLoadingStatus(false)
    }

    const BridgeBtn = () => {
        if (bridgeFromValue == chainId) {
            return (
                <Button style={{ marginTop: 35, maxWidth: 400, width: '100%' }} type="primary" onClick={hadnleBridgeClick} >{t('Continue')}</Button>
            )
        }else{
            return (
                <Button style={{ marginTop: 35, maxWidth: 400, width: '100%' }} type="primary" onClick={() => switchChain(bridgeFromValue)} >{t('Switch')}</Button>
            )
        }
    }


    return (
        <div className="bridge">
          <div className="bridgeChain">
            <div className="bridgeFrom">
              <p>From</p>
              <Select
                value={bridgeFromValue}
                style={{
                  maxWidth: 180,
                  width: '100%'
                }}
                onChange={handleBridgeFromChange }
                options={bridgeOptions}
                optionLabelProp="label"
              />
            </div>
            <div style={{width: 30}}></div>
            <div className="bridgeTo">
              <p>To</p>
              <Select
                value={bridgeToValue}
                style={{
                  maxWidth: 180,
                  width: '100%'
                }}
                onChange={handleBridgeToChange }
                options={bridgeOptions}
                optionLabelProp="label"
              />
            </div>
          </div>
          <div className="bridgeAmount">
            <div className="bridgeAmountBalance">
              <p className="lineWord">{t('Total-amount')}</p>
              <div className="lineWord">{t('Balance')}: {formatNumberFixed(balance)}</div>
            </div>
            <Input 
              addonBefore="TD"
              suffix={
                <Button  size="small" type="primary" onClick={() => setMaxBridgeValue()} >Max</Button>
              }
              style={{
                maxWidth: 400,
                width: '100%'
              }}
              value={bridgeTdAmount}
              onChange={handleBridgeInputChange}
            />
          </div>
          <div className="bridgeline">
              <span className="lineWord">{t('receive')}</span>
              <span className="valueWord">{formatNumber(bridgeTdAmount)} TD</span>
          </div>
          <div className="bridgeline">
              <span className="lineWord">{t('gas-fee')}</span>
              <span className="valueWord">{estimateFee} ETH</span>
          </div>

          {/* <Button style={{ marginTop: 35, width: 400, }} type="primary" onClick={this.hadnleBridgeClick} >Continue</Button> */}
          <BridgeBtn />
        
          <div className="bridgeline" style={{display: 'flex', justifyContent:'center'}}>
              <span className="lineWord">Powered by LayerZero</span>
          </div>
          <LoadingModal loadingStatus={loadingStatus} />
        </div>
    )
}
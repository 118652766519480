import { useState, useEffect } from "react"
import { ethers } from "ethers"

export default function useChainId() {
  const [chainId, setChainId] = useState(null)

  const getChainId = async () => {
    const _provider = new ethers.providers.Web3Provider(window.ethereum);
    const hexChainId = ethers.utils.hexValue((await _provider.getNetwork()).chainId);
    setChainId(hexChainId)
  }

  useEffect(() => {
    getChainId()
  }, [])
  return chainId
}
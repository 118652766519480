import { useCallback, useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
// import MyIcon from "@/components/icon";
import { setToken } from "../utils/token";
import { useLocation, useNavigate } from 'react-router-dom'
// import { setUserInfoAction } from "@/store/user/action";
// import { login } from "@/api";
// import { UserInfo } from "@/types"
import "./login.scss";
import axios from 'axios';

axios.defaults.baseURL = 'https://tizi.money/login/api';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const initialValues = {
//   remember: true,
//   ...getLocalUser(),
// }

const IPT_RULE_USERNAME = [
  {
    required: true,
    message: "请输入用户名",
  },
];

const IPT_RULE_PASSWORD = [
  {
    required: true,
    message: "请输入密码",
  },
];

export default function Login() {
//   const dispatch = useDispatch()
//   const setUserInfo = useCallback((info) => dispatch(setUserInfoAction(info)), [dispatch])
  const { btnLoad, onFinish } = useLogin();
  const navigate = useNavigate();


  function useLogin() {
    const [btnLoad, setBtnLoad] = useState(false);
    const onFinish = (values) => {
      setBtnLoad(true);
      console.log(values)
      axios.post('/login', {
        username: values.account,
        password: values.pswd
      }).then((res) => {
        console.log(res, 'ssdd')
        if (res.data.status && res.data.status == 200) {
          setToken('111');
          navigate('/', {replace: true}) 
        }else{
          message.error(res.data.message)
        }
        setBtnLoad(false);
      })
      // login(values)
      //   .then((res) => {
      //     const { data, msg, status, token } = res;
      //     setBtnLoad(false);
      //     if (status === 1 && !data) return;
      //     const info = Object.assign({ isLogin: true }, data)
      //     setToken(token);
      //     message.success(msg);
      //     if (values.remember) {
      //     //   saveUser(info);
      //     }
      //   })
      //   .catch(() => {
      //     setBtnLoad(false);
      //   });
    };
    return { btnLoad, onFinish };
  }

  return (
    <div className="login-container">
      <div className="wrapper">
        {/* <div className="title">react-ant-admin</div> */}
        <div className="title">欢迎使用，请先登录</div>
        <Form
          className="login-form"
        //   initialValues={initialValues}
          onFinish={onFinish}
        >
          <Form.Item name="account" rules={IPT_RULE_USERNAME}>
            <Input
            //   prefix={<MyIcon type="icon_nickname" />}
              placeholder="请输入账号"
            />
          </Form.Item>
          <Form.Item name="pswd" rules={IPT_RULE_PASSWORD}>
            <Input
            //   prefix={<MyIcon type="icon_locking" />}
              type="password"
              autoComplete="off"
              placeholder="请输入密码"
            />
          </Form.Item>
          {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>记住我</Checkbox>
            </Form.Item>
          </Form.Item> */}
          <Form.Item className="btns">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={btnLoad}
            >
              登录
            </Button>
            <Button htmlType="reset">重置</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
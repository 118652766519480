import { useBalance } from "../../hooks/useBalance";
import { formatNumberFixed } from "../../utils/util";

export default function WTDBalance() {

    const { balance, usdcBalance} = useBalance()


//Tizi Dollar (TD) USD Coin (USDC) Wrapped Tizi Dollar (WTD)

    return (
        <div className="container d-flex justify-content-center mb-5">
        <div className="d-flex w-100 justify-content-around" style={{maxWidth: '600px'}}>
            <div className="d-flex flex-column align-items-center">
                <div>{formatNumberFixed(usdcBalance)}</div>
                <div>USDC</div>
            </div>
            <div className="d-flex flex-column align-items-center">
                <div>{formatNumberFixed(balance)}</div>
                <div>TD</div>
            </div>
        </div>
        </div>
    );
}

import React from "react";
import { ethers } from "ethers";
import { Card, InputNumber, Button, Table, Select, Input, Space, message, Typography  } from 'antd';
import contractAddress from "../../contracts/contract-address-last.json";
import PoLottoExchange from "../../contracts/PoLottoExchangeABI.json"
import TDTokenABI from "../../contracts/TDToken.json";
import CHAIN_ID from "../../assets/chainIds.json";
import Rebase from "../../contracts/RebaseABI.json"
import "../../bridgeCom.scss"
import polyImage from '../../images/polygon.png'
import { getChainNameById } from "../../utils/util";
import { mianChianId, supportedChain } from "../../utils/config";
import { withTranslation } from "react-i18next";
import WithdrawTable from "./NFT/WithdrawTable";
import WithdrawFormOld from "./NFT/WithdrawForm";
import { chainRpc } from "../../utils/config";
import {
  SyncOutlined,
} from '@ant-design/icons';

const { Title, Paragraph, Text, Link } = Typography;

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}


class ExchangeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.sub = React.createRef()
    this.state = {
      exchangeRate: '',
      dercAmount: "",
      ddAmount: "",
      maxDercAmount: "",
      bridgeTdAmount: "0",
      maxBridgeAmount: "",
      activeTab: 'Burn',
      rebaseData: [],
      estimateFee: "0",
      bridgeFromValue: '0x2105',
      bridgeToValue: '0x89',
      bridgeOptions: [
        {
          value: '0x2105',
          label: (
            <Space style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <img src={polyImage} alt="" style={{
                width: 16,
                height: 16
              }}/>
              <span>Base</span>
            </Space>
          )
        },
        {
          value: '0x89',
          label: (
            <Space>
              <img src={polyImage} alt="" style={{
                width: 16,
                height: 16
              }}/>
              <span>Polygon</span>
            </Space>
          )
        },
      ],
      bridgeButtonStatus: true,
      bufferAmount: 0,
      bufferSpinning: false
    };
    
  }
  

  initPoLotto() {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    this._poLotto = new ethers.Contract(
      contractAddress.Contract[0].address,
      PoLottoExchange,
      this._provider.getSigner()
    );
  }

  async _getExchangeRate() {
    // console.log(getChainNameById(this.state.bridgeFromValue))
    if (!this._poLotto) {
      this.initPoLotto()
    }
    console.log('333', this._provider)
    const exchangeRate = await this._poLotto.DDExchangeRate();
    // const exchangeRate = 0.6
    console.log('112222', ethers.utils.formatUnits(exchangeRate.toString(), 5))
    this.setState({ exchangeRate: ethers.utils.formatUnits(exchangeRate.toString(), 5) });
    return ethers.utils.formatUnits(exchangeRate.toString(), 5) 
  }
  
  componentDidMount() {
    this.initPoLotto()
    //0x61 0x89
    if (this.props.chainId == mianChianId) {
      this._getExchangeRate() 
    }
    this.fetchBufferAmount()
    // this.fetchRebaseHistory();
    // this.fetchRebase();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.queueDataUpdated && this.props.queueDataUpdated) {
      this.sub.current.updateQueueOrNFT()
      this.props.resetQueueDataUpdated();
    }
    console.log(prevProps)
  }


  fetchRebaseHistory = async () => {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    this._rebase = new ethers.Contract(
      contractAddress.Event[0].address,
      Rebase,
      this._provider.getSigner(0)
    );
    // debugger
    const filter = this._rebase.filters.RebaseAction();
    const logs = await this._rebase.queryFilter(filter,42372845, 42372955).catch(err => console.log('fffgg', err));
    const rebaseData = logs.map(log => ({
      assetBeforeRebase: parseInt(log.args.assetBeforeRebase._hex, 16),
      newTotalAsset: parseInt(log.args.newTotalAsset._hex, 16),
    }));
    this.setState({ rebaseData });
  };

  handleClickBuffer = async () => {
    this.setState({bufferSpinning: true})
    await this.fetchBufferAmount()
    this.setState({bufferSpinning: false})
    message.success('Refresh Buffer Success!')
  }

  fetchBufferAmount = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const usdcContract = new ethers.Contract(
      contractAddress.USDCToken.base,
      TDTokenABI,
      provider
    );
    const buffer = await usdcContract.balanceOf(contractAddress.Vault.base)
    const bufferAmount = ethers.utils.formatUnits(buffer, 6)
    this.setState({bufferAmount})
  }

  fetchRebase = () => {
    this._rebase.on("RebaseAction", (assetBeforeRebase, newTotalAsset) => {
      console.log("实时",assetBeforeRebase, newTotalAsset)
      const newData = {
        assetBeforeRebase: parseInt(assetBeforeRebase._hex, 16),
        newTotalAsset: parseInt(newTotalAsset._hex, 16),
      };
      this.setState((prevState) => ({
        rebaseData: [...prevState.rebaseData, newData],
      }));
    });
  };

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleInputChange = async(value) => {
    let exchangeRate = await this._getExchangeRate().catch(err => console.log('err', err))
    if (!exchangeRate) {
      exchangeRate = 0.9996
    }
    this.setState({
      dercAmount: value,
      ddAmount: value * exchangeRate,
    });
    console.log('li', value)
  };

  handleExchangeClick = () => {
    const { dercAmount } = this.state;
    console.log(ethers.utils.parseUnits(dercAmount, 18))
    this.props.exchangeTokens(dercAmount, "Exchange");
  };

  initTDToken = () => {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    this._TDtoken = new ethers.Contract(
      contractAddress.TDToken[getChainNameById(this.state.bridgeFromValue)],
      TDTokenABI,
      this._provider.getSigner(0)
    )
  }

  estimateGasFee = async (amount) => {
    this.initTDToken()
    const qty = ethers.utils.parseEther(amount)
    console.log('l222i', this._TDtoken)
    //打包address字段和交易发起者地址
    const coder =  ethers.utils.defaultAbiCoder
    const toAddressBytes = coder.encode(["address"], [localStorage.getItem('selectedAddress')])
    

    // 获取远程chainID
    const remoteChainId = CHAIN_ID[getChainNameById(this.state.bridgeToValue)]
    const adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 200000])
    const fees = await this._TDtoken.estimateSendFee(remoteChainId, toAddressBytes, qty, false, adapterParams).catch(err => {
      console.log(err)
    })
    console.log('li', fees)
    console.log('li3333', ethers.utils.formatEther(fees[0]))
    this.setState({
      estimateFee: ethers.utils.formatEther(fees[0])
    })
  }

  hadnleBridgeClick = async () => {
    // formatNumber(this.state.bridgeTdAmount)
    // this.props.bridgeTokens(formatNumber(this.state.bridgeTdAmount));
    this.setState({bridgeButtonStatus: false})
    this._TDtoken = new ethers.Contract(
      contractAddress.TDToken[getChainNameById(this.state.bridgeFromValue)],
      TDTokenABI,
      this._provider.getSigner(0)
    )
    const qty = ethers.utils.parseEther(formatNumber(this.state.bridgeTdAmount))
    console.log('l222i', this._TDtoken)
    //打包address字段和交易发起者地址
    const coder =  ethers.utils.defaultAbiCoder
    const toAddressBytes = coder.encode(["address"], [localStorage.getItem('selectedAddress')])
    
    const zero = ethers.constants.AddressZero

    // 获取远程chainID
    //getChainNameById(this.state.bridgeToValue)
    const remoteChainId = CHAIN_ID[getChainNameById(this.state.bridgeToValue)]
    const adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 200000])
    const fees = await this._TDtoken.estimateSendFee(remoteChainId, toAddressBytes, qty, false, adapterParams).catch(err => console.log(err))
    console.log('li', fees)
    const sendTx = await this._TDtoken.sendFrom(
      localStorage.getItem('selectedAddress'),
      remoteChainId,
      toAddressBytes,
      qty,
      {
        refundAddress: localStorage.getItem('selectedAddress'),
        zroPaymentAddress: zero,
        adapterParams,
      },
      {
        value: fees[0]
      }
    ).catch(err => {
      console.log(err);
      message.error(err.data.message) 
    })
    
    if (sendTx) {
      const tx = await sendTx.wait().catch(err => console.log(err))
      console.log('li', tx)
      this.props.updateBalances()
      message.success('tx:'+ tx.transactionHash + '.This transcation is completed. Your will receive within 24 hours') 
    }
    this.setState({bridgeButtonStatus : true})
  }

  setMaxValue = async(value) => {
    const exchangeRate = await this._getExchangeRate()
    if (value === 'dercAmount') {
      this.setState({ 
        dercAmount: this.props.balances['USDCToken'],
        ddAmount: this.props.balances['USDCToken'] * exchangeRate,
       });
    }
  }
  setMaxBridgeValue = () => {
    console.log(this.props.balances['TDToken'])
    this.estimateGasFee(this.props.balances['TDToken'])
    this.setState({ bridgeTdAmount: this.props.balances['TDToken'] });
  }

  handleBridgeFromChange = (value) => {
    console.log(value)
    if (value == this.state.bridgeToValue) {
      this.setState({
        bridgeToValue: this.state.bridgeFromValue,
        bridgeFromValue: value
      })
    }else{
      this.setState({bridgeFromValue: value})
    }
    // this.estimateGasFee(this.props.balances['TDToken'])
  }
  handleBridgeToChange = (value) => {
    console.log(value)
    if (value == this.state.bridgeFromValue) {
      this.setState({
        bridgeFromValue: this.state.bridgeToValue,
        bridgeToValue: value
      })
    }else{
      this.setState({bridgeToValue: value})
    }
    // this.estimateGasFee(this.props.balances['TDToken'])
  }
  handleBridgeInputChange = (e) =>{
    // value.replace(/[^\-?\d.]/g,'')
    console.log(e)
    let value = e.target.value.replace(/[^\-?\d.]/g,'')
    if (!e.target.value) {
      value = 0
    }
    if (value > this.props.balances['TDToken']) {
      value = this.props.balances['TDToken']
    }
    this.estimateGasFee(formatNumber(value))
    this.setState({ bridgeTdAmount: value })

    // console.log('1liu', e)
  }


  switchChain = async (chainId) => {
    await window.ethereum.request({
      "method": "wallet_switchEthereumChain",
      "params": [
        {
          "chainId": chainId
        }
      ]
    }).catch(err => console.log(err));
  }


  render() {
    const tabList = [
      // {
      //   key: 'Mint',
      //   label: this.props.t('Mint'),
      // },
      {
        key: 'Burn',
        label: this.props.t('Burn'),
      },
      // {
      //   key: 'Rebase',
      //   tab: 'Rebase',
      // },
      // {
      //   key: 'Bridge',
      //   label: this.props.t('Bridge'),
      // },
    ];
    const columns1 = [
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: 200,
        align: 'center',
      },
      {
        title: 'AssetBeforeRebase',
        dataIndex: 'assetBeforeRebase',
        key: 'assetBeforeRebase',
        width: 200,
        align: 'center',
      },
      {
        title: 'NewTotalAsset',
        dataIndex: 'newTotalAsset',
        key: 'newTotalAsset',
        align: 'center',
      },
    ];
    let depositContent = undefined
    let depositButton = undefined
    let bookContent = undefined
    let bridgeButton = undefined
    if (this.props.depositButton) {
      depositButton = (
        this.props.t('Deposit')
      )
    }else{
      depositButton = (
        this.props.t('Waiting')
      )
    }
    if (this.state.bridgeFromValue == this.props.chainId) {
      bridgeButton = (
        <Button style={{ marginTop: 35, width: 400, }} type="primary" onClick={this.hadnleBridgeClick} loading={!this.state.bridgeButtonStatus}>{this.state.bridgeButtonStatus?this.props.t('Continue'): this.props.t('waiting')}</Button>
      )
    }else{
      bridgeButton = (
        <Button style={{ marginTop: 35, width: 400, }} type="primary" onClick={() => this.switchChain(this.state.bridgeFromValue)} >{this.props.t('Switch')}</Button>
      )
    }
    if (this.props.chainId == mianChianId) {
      depositContent = (
        <div style={{width: '100%', padding: '0 30px'}}>
          <div style={{ display: 'flex', flexDirection: 'column',width: '100%' }}>
            From
            <div style={{ display: 'flex', alignItems: 'center' }} >
              <InputNumber
                addonAfter="USDC"
                style={{
                  width: '100%',
                  maxWidth: 400,
                }}
                value={this.state.dercAmount}
                min="1"
                max={this.props.balances['USDCToken']}
                step="0.000000000000000001"
                onChange={this.handleInputChange}
                stringMode
                precision={18}
              />
              <Button style={{ marginLeft: 15 }} size="small" type="primary" onClick={() => this.setMaxValue("dercAmount")} >Max</Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column',width: '100%' }}>
            To
            <InputNumber
              addonAfter="TD"
              style={{
                width: '100%',
                maxWidth: 400,
              }}
              value={this.state.ddAmount}
              stringMode
              disabled
            />
          </div>
          <Button style={{ marginTop: 35, width: '100%',maxWidth: 400, }} type="primary" onClick={this.handleExchangeClick} loading={!this.props.depositButton}>{depositButton}</Button>
        </div>
      )
      bookContent = (
        <div style={{width: '100%'}}>
          <WithdrawFormOld bookButton={this.props.bookButton} balances={this.props.balances['TDToken']} exchangeTokens={this.props.exchangeTokens}/>
          <div style={{marginTop: '20px'}}>
            <Paragraph strong>
              Current Buffer: {this.state.bufferAmount} USDC
              <SyncOutlined style={{cursor: 'pointer',marginLeft: '5px'}} onClick={this.handleClickBuffer} spin={this.state.bufferSpinning} />
            </Paragraph>
          </div>
          <div style={{margin: '20px 0'}}>
            <Paragraph strong>{this.props.t('withdraw-small-text')}</Paragraph>
          </div>
          <WithdrawTable ref={this.sub} updateBalances={() => this.props.updateBalances()}/>
        </div>
      ) 
    }else{
      depositContent = (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <h4>{this.props.t('unspport-text')}</h4>
          <Button style={{ marginTop: 35, width: 400, }} type="primary" onClick={() => this.switchChain('0x2105')} >{this.props.t('unspport-btn')}</Button>
        </div>
      )
      bookContent= (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <h4>{this.props.t('unspport-text')}</h4>
          <Button style={{ marginTop: 35, width: 400, }} type="primary" onClick={() => this.switchChain('0x2105')} >{this.props.t('unspport-btn')}</Button>
        </div>
      )
    }
    const contentList = {
      // Mint: depositContent,
      Burn: bookContent,
      Rebase: (
        <div>
          <Button
            style={{ marginTop: 35, width: 400 }}
            type="primary"
            onClick={this.handleRebaseClick}
          >
            Rebase
          </Button>
          <Table style={{ marginTop: 50 }} dataSource={this.state.rebaseData} columns={columns1} />
        </div>
      ),
      // Bridge: (
      //   <BridgeTD balances={this.props.balances} chainId={this.props.chainId} updateBalances={this.props.updateBalances}/>
      //   //this.props.balances
      //   // <div className="bridge">
      //   //   <div className="bridgeChain">
      //   //     <div className="bridgeFrom">
      //   //       <p>From</p>
      //   //       <Select
      //   //         defaultValue="Polygon"
      //   //         value={this.state.bridgeFromValue}
      //   //         style={{
      //   //           width: 180,
      //   //         }}
      //   //         onChange={this.handleBridgeFromChange }
      //   //         options={this.state.bridgeOptions}
      //   //         optionLabelProp="label"
      //   //         // optionRender={(option) => (
      //   //         //   <Space>
      //   //         //     <img src={polyImage} alt="" style={{
      //   //         //       width: 16,
      //   //         //       height: 16
      //   //         //     }}/>
      //   //         //     <span>{option.data.label}</span>
      //   //         //   </Space>
      //   //         // )}
      //   //       />
      //   //     </div>
      //   //     <div className="bridgeTo">
      //   //       <p>To</p>
      //   //       <Select
      //   //         defaultValue="BSC"
      //   //         value={this.state.bridgeToValue}
      //   //         style={{
      //   //           width: 180,
      //   //         }}
      //   //         onChange={this.handleBridgeToChange }
      //   //         options={this.state.bridgeOptions}
      //   //         optionLabelProp="label"
      //   //       />
      //   //     </div>
      //   //   </div>
      //   //   <div className="bridgeAmount">
      //   //     <div className="bridgeAmountBalance">
      //   //       <p className="lineWord">{this.props.t('Total-amount')}</p>
      //   //       <div className="lineWord">{this.props.t('Balance')}: {this.props.balances && this.props.balances['TDToken']}</div>
      //   //     </div>
      //   //     <Input 
      //   //       addonBefore="TD"
      //   //       suffix={
      //   //         <Button  size="small" type="primary" onClick={() => this.setMaxBridgeValue()} >Max</Button>
      //   //       }
      //   //       style={{
      //   //         width: 400,
      //   //       }}
      //   //       value={this.state.bridgeTdAmount}
      //   //       onChange={this.handleBridgeInputChange}
      //   //     />
      //   //   </div>
      //   //   <div className="bridgeline">
      //   //       <span className="lineWord">{this.props.t('receive')}</span>
      //   //       <span className="valueWord">{formatNumber(this.state.bridgeTdAmount)}TD</span>
      //   //   </div>
      //   //   <div className="bridgeline">
      //   //       <span className="lineWord">{this.props.t('gas-fee')}</span>
      //   //       <span className="valueWord">{this.state.estimateFee} ETH</span>
      //   //   </div>

      //   //   {/* <Button style={{ marginTop: 35, width: 400, }} type="primary" onClick={this.hadnleBridgeClick} >Continue</Button> */}
      //   //   {bridgeButton}
        
      //   //   <div className="bridgeline" style={{display: 'flex', justifyContent:'center'}}>
      //   //       <span className="lineWord">Powered by LayerZero</span>
      //   //   </div>
      //   // </div>
      // ),
    };


    return (
      <div className="container d-flex justify-content-center">
        <Card
          style={{ maxWidth: 600, width: '100%' }}
          tabList={tabList}
          activeTabKey={this.state.activeTab}
          onTabChange={this.handleTabClick}
          bodyStyle={{
            display: 'flex',
            // flex-direction: column;
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {contentList[this.state.activeTab]}
        </Card >
      </div>
    );
  }
}

export default withTranslation()(ExchangeComponent)



import { Space, Typography } from 'antd'
import { TwitterOutlined,GithubOutlined,  YoutubeOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next"
import './footer.scss'

export default function Footer(){
    const {t} = useTranslation()
    const {Link} = Typography
    return (
        <div className="main-container-blue">
            <div className="footer-container container">
                <div className='row'>

                    <div className='col-lg-4 col-12'>
                        <div className='first-column'>
                            <div className='title-container'>
                                <div className='title-border'></div>
                                <div className='title-content'>
                                    <div className='title'>Tizi</div>
                                    <div className='sub-title' style={{color: '#bfc2c2'}}> {t('multi-text')} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='col-lg-3 col-6'>
                        <div className='second-column'>
                            <div className='second-title'>{t('products')}</div>
                            <div className='second-container'>
                                <div className='second-single-col'>
                                    <Link href='https://github.com/' target="_blank" className='col-bottom'>
                                        <div className='link-text'>
                                            USDC
                                        </div>
                                    </Link>
                                    <Link href='https://github.com/' target="_blank" className='col-bottom'>
                                        <div className='link-text'>
                                            TD
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='col-lg-4 col-6'>
                        <div className='second-column'>
                            <div className='second-title'>{t('find-us')}</div>
                            <div className='second-container'>
                                <div className='second-single-col'>
                                    <Space className='col-bottom'>
                                        <TwitterOutlined/>
                                        <div>Twitter</div>
                                    </Space>
                                    <Space className='col-bottom'>
                                        <GithubOutlined/>
                                        <div>Github</div>
                                    </Space>
                                    <Space className='col-bottom'>
                                        <YoutubeOutlined/>
                                        <div>Youtube</div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 col-6'>
                        <div className='second-column'>
                            <div className='second-title'>{t('developer')}</div>
                            <div className='second-container' style={{marginBottom: '20px'}}>
                                <div className='second-single-col'>
                                    <Link href='https://tizimoney.gitbook.io/' target="_blank" className='col-bottom'>
                                        <div className='link-text'>
                                        {t('documentation')}
                                        </div>
                                    </Link>
                                    <Link href='https://tizimoney.gitbook.io/tizi/other/audits' target="_blank" className='col-bottom'>
                                        <div className='link-text'>
                                        {t('audits')}
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className='second-title'>{t('support-center')}</div>
                            <div className='second-container'>
                                <div className='second-single-col'>
                                    <Link href='https://github.com/' target="_blank" className='col-bottom'>
                                        <div className='link-text'>
                                        {t('help-discord')}
                                        </div>
                                    </Link>
                                    <Link href='https://tizimoney.gitbook.io/tizi/other/privacy-policy' target="_blank" className='col-bottom'>
                                        <div className='link-text'>
                                        {t('privacy-policy')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
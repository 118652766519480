 // 封装本地存储的操作
const LANGUAGE_KEY = 'i18nextLng'

// 获取 Language
export function getLanguage () {
  return localStorage.getItem(LANGUAGE_KEY)
}
// 判断有无 Language
export function hasLanguage () {
  return !!getLanguage()
}

import { useState } from "react";
import { Button } from "antd";
import { ethers } from "ethers";
import PoLottoExchange from "../../contracts/PoLottoExchangeABI.json";
import contractAddress from "../../contracts/contract-address.json";
import GenerateImageWord from "../generateImageWord/generateImageWord";

export function NFTAction({
  text,
  record,
  index,
  fetchNftList,
  updateBalances,
  showFinshBtn = true,
}) {
  const [withdrawButton, setWithdrawButton] = useState(false);

  async function withdrawNFTAction(record) {
    setWithdrawButton(true);
    const _provider = new ethers.providers.Web3Provider(window.ethereum);
    const _poLotto = new ethers.Contract(
      contractAddress.Contract[0].address,
      PoLottoExchange,
      _provider.getSigner(0)
    );
    console.log(record.queueId);
    try {
      const withdrawNftTx = await _poLotto.withdrawFromNFT(record.queueId);
      const result = await withdrawNftTx.wait();
      console.log(result);
      setWithdrawButton(false);
      fetchNftList();
      updateBalances();
    } catch (error) {
      console.log(error);
      setWithdrawButton(false);
    }
  }
  if (showFinshBtn) {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <GenerateImageWord
          amount={record.amount}
          queueId={record.queueId}
          mintDate={record.mintTime}
        />
        <Button
          type="primary"
          onClick={() => withdrawNFTAction(record)}
          loading={withdrawButton}
        >
          {withdrawButton ? "Waiting" : "Finished"}
        </Button>
      </div>
    );
  } else {
    return (
      <GenerateImageWord
        amount={record.amount}
        queueId={record.queueId}
        mintDate={record.mintDate}
      />
    );
  }
}

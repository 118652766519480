import { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { setAddress } from '../utils/address';
import { mianChianId } from "../utils/config"
const useContract = (contractAddress, contractABI) => {
  const [contract, setContract] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [isMainChain, setIsMainChain] = useState(false);


  const checkConnection = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      console.log(accounts);
      if (accounts.length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleChainChanged = useCallback((_chainId) => {
    setChainId(_chainId);
    setIsMainChain(_chainId === mianChianId);
    window.location.reload()
    console.log('chainId', _chainId);
    if (_chainId !== mianChianId) {
      // setError(`Please switch to the correct network.`);
      setContract(null); // Optionally clear contract if chain is not supported
    } else {
      // setError(null);
      initializeContract(provider, signer);
    }
  }, [mianChianId, provider, signer])

  const initializeContract = useCallback(async (_provider, _signer) => {
    const _contract = new ethers.Contract(contractAddress, contractABI, _signer);
    setContract(_contract);
  }, [contractAddress, contractABI]);

  useEffect(() => {
    const initProvider = async () => {
      if (window.ethereum) {
        try {
          const isAlreadyConnected = await checkConnection();
          console.log('isAlreadyConnected', isAlreadyConnected)
          if (!isAlreadyConnected) {
            // 请求用户连接MetaMask
            const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log(address)
            setAddress(address)
          }
          const _provider = new ethers.providers.Web3Provider(window.ethereum);
          const hexChainId = ethers.utils.hexValue((await _provider.getNetwork()).chainId);
          setIsMainChain(mianChianId === hexChainId);
          console.log('hexChainId', hexChainId)
          const _signer = _provider.getSigner();
          const _contract = new ethers.Contract(contractAddress, contractABI, _signer);

          setProvider(_provider);
          setSigner(_signer);
          setContract(_contract);
          // 监听链ID变化
          window.ethereum.on('chainChanged', handleChainChanged);
        } catch (error) {
          console.error('User denied account access', error);
        }
      }
    };

    initProvider();

    //清理监听器
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [contractAddress, contractABI]);

  const callMethod = useCallback(async (methodName, ...args) => {
    if (!contract) {
      console.error('Contract is not initialized');
      return;
    }

    try {
      const response = await contract[methodName](...args);
      return response;
    } catch (error) {
      console.error('Error calling contract method:', error);
    }
  }, [contract]);

  return {
    provider,
    signer,
    contract,
    callMethod,
    isMainChain
  };
};

export default useContract;

import { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import { Segmented, Table, Tooltip, Button, Statistic, Tag, Spin } from "antd"
import { NFTAction } from "./NFTAction"
import { LoadingModal } from "../../../components/loading/LoadingModal";
import { WarningOutlined } from '@ant-design/icons';
import { ethers } from "ethers";
import PoLottoExchange from "../../../contracts/PoLottoExchangeABI.json";
import contractAddress from "../../../contracts/contract-address-last.json";
import NFTAbi from "../../../contracts/NFTabi.json"
import "./nft.scss"

const WithdrawTable = forwardRef(({updateBalances}, ref) => {
    useImperativeHandle(ref, () => ({
      fetchQueueData: fetchQueueData,
      fetchNFTData: fetchNFTData,
      updateQueueOrNFT: updateQueueOrNFT
    }))
    const { Countdown } = Statistic;
    const nftColums = [
        {
          title: 'QueueId',
          dataIndex: 'queueId',
          key: 'queueId',
          width: 100,
          align: 'center',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          width: 100,
          align: 'center',
          responsive: ['md'],
        },
        {
          title: 'MintTime',
          dataIndex: 'mintTime',
          key: 'mintTime',
          width: 200,
          align: 'center',
          responsive: ['md'],
        },
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (text, record, index) => (
            <NFTAction text={text} record={record} index={index} fetchNftList={() => fetchNFTData()} updateBalances={ () => updateBalances()}/>
            // actionContent(text, record,index)
          ),
        },
    ]

    const waitingNftColums = [
      {
        title: 'QueueId',
        dataIndex: 'queueId',
        key: 'queueId',
        width: 100,
        align: 'center',
        responsive: ['md'],
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 100,
        align: 'center',
        responsive: ['md'],
      },
      {
        title: 'EstimateTime',
        key: 'estimateTime',
        width: 200,
        align: 'center',
        render: (text, record, index) => (
          <Tag color="green">
            <Countdown style={{'fontSize': '16px'}} value={record.waitingTime} onFinish={()=> fetchNFTWaitingData()} format="HH : mm : ss "/> 
          </Tag>
        )
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record, index) => (
          <NFTAction showFinshBtn={false} text={text} record={record} index={index} fetchNftList={() => fetchNFTData()} updateBalances={ () => updateBalances()}/>
        ),
      },
    ]

    const description = 'Bridge is temporarily unavailable, we apologize for the inconvenience'

    const columns = [
      // {
      //   title: 'Time',
      //   dataIndex: 'time',
      //   key: 'time',
      //   width: 200,
      //   align: 'center',
      // },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 200,
        align: 'center',
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record, index) => (
          record.status
            ? <Tooltip title={description} color='#fff1f0' overlayInnerStyle={{ color: '#cf1322' }} >
              <WarningOutlined style={{ fontSize: 30, color: 'red' }} />
            </Tooltip>
            : <Button type="primary" onClick={() => withdraw(index)}>Finished</Button>
        ),
      },
    ];

    const [queueData, setQueueData] = useState([])
    const [tableColums, setTableColums] = useState(columns)
    const [segmentedType, setSegmentedType] = useState('WaitingNFT')
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [loadingData, setLoadingData] = useState(false)

    const updateQueueOrNFT = () => {
      if(segmentedType === 'WaitingNFT'){
        fetchNFTWaitingData()
      }else{
        fetchNFTData()
      }
    }

    const fetchQueueData = async () => {
        const queueData = []; // 创建一个新的数组来存储数据
        setLoadingData(true)
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const _poLotto = new ethers.Contract(
            contractAddress.Contract[0].address,
            PoLottoExchange,
            _provider.getSigner(0)
        );
        const data = await _poLotto.queueingStatus1();
        console.log(data)
        data.map((item, index) => {
          const formatAmount = item[0].toString()
          const times = parseInt(item[1]) * 1000;
          const status = item[2]
          const dateTime = new Date(times);
          const formattedDate = dateTime.toLocaleString();
          const timeDifference = times - Date.now();
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          const newData = {
            amount: ethers.utils.formatUnits(ethers.BigNumber.from(formatAmount), 6),
            time: formattedDate,
            // timeDifference: `wait ${days}d${hours}h${minutes}m${seconds}s`,
            timeDifference: timeDifference,
            show: days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0,
            status
          };
        //   console.log("数据", newData)
          queueData[index] = newData; // 将数据添加到新的数组中，而不是直接修改 state
          return newData;
        })
        console.log("数据", queueData)
        setTableColums(columns)
        setQueueData(queueData)
        setLoadingData(false)
      }


    const fetchNFTWaitingData = async() => {
      setLoadingData(true)
      const _provider = new ethers.providers.Web3Provider(window.ethereum);
      const _poLotto = new ethers.Contract(
        contractAddress.Contract[0].address,
        PoLottoExchange,
        _provider.getSigner(0)
      );
      const nftControct = new ethers.Contract(
        contractAddress.NFT.NFTaddress,
        NFTAbi,
        _provider.getSigner(0)
      );
        const nftIds = await _poLotto.NFTWaitQueue().catch(err => {
          console.log(err)
        })
        let nftList = []
        console.log(nftIds)
        for (let index = 0; index < nftIds.length; index++) {
          const element = nftIds[index];
          console.log(element.toString())
          const nftInfoTemp = await nftControct.tokenURI(element.toString()).catch(err => {
            console.log(err)
          })
          const nftFullInfo = await _poLotto.withdrawNFTs(element.toString()).catch(err => {
            console.log(err)
          })
          console.log('nftFullInfo:', nftFullInfo)
          const nftInfo = JSON.parse(window.atob(nftInfoTemp))
          nftInfo.amount = ethers.utils.formatUnits(ethers.BigNumber.from(nftInfo.amount), 6)
          nftInfo.mintDate = new Date(parseInt(nftInfo.mintTime) * 1000).toLocaleDateString()
          nftInfo.mintTime = new Date(parseInt(nftInfo.mintTime) * 1000).toLocaleString()
          nftInfo.estimateTime = new Date(parseInt(nftFullInfo.expireDate) * 1000).toLocaleString()
          nftInfo.waitingTime = parseInt(nftFullInfo.expireDate) * 1000
          nftList = [...nftList ,nftInfo]
        }
        console.log(nftList)
        setTableColums(waitingNftColums)
        setQueueData([...nftList])
        setLoadingData(false)
    }

    const fetchNFTData = async () => {
      setLoadingData(true)
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const nftControct = new ethers.Contract(
          contractAddress.NFT.NFTaddress,
          NFTAbi,
          _provider.getSigner(0)
        );
        const _poLotto = new ethers.Contract(
          contractAddress.Contract[0].address,
          PoLottoExchange,
          _provider.getSigner(0)
        );
        const nftIds = await _poLotto.NFTWithdrawQueue().catch(err => {
          console.log(err)
        })
        console.log(nftIds)
        let nftList = []
        for (let index = 0; index < nftIds.length; index++) {
          const element = nftIds[index];
          const nftInfoTemp = await nftControct.tokenURI(element.toString())
          const nftInfo = JSON.parse(window.atob(nftInfoTemp))
          nftInfo.amount = ethers.utils.formatUnits(ethers.BigNumber.from(nftInfo.amount), 6)
          nftInfo.mintTime = new Date(parseInt(nftInfo.mintTime) * 1000).toLocaleString()
          nftList = [...nftList ,nftInfo]
        }
        console.log(nftList)
        setTableColums(nftColums)
        setQueueData([...nftList])
        setLoadingData(false)
    }

    const withdraw = async (index) => {
        console.log(index)
        setLoadingStatus(true)
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const _poLotto = new ethers.Contract(
            contractAddress.Contract[0].address,
            PoLottoExchange,
            _provider.getSigner(0)
        );
        try {
          const withdrawTx = await _poLotto.withdraw(index)
          const receipt = await withdrawTx.wait();
          if (receipt.status === 0) {
            throw new Error("withdraw failed");
          }
        } catch (error) {
          console.log(error)
        }
        updateBalances();
        fetchQueueData()
        setLoadingStatus(false)
      }
    const withdrawAllNft = async() => {
      try {
        setLoadingStatus(true)
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const _poLotto = new ethers.Contract(
          contractAddress.Contract[0].address,
          PoLottoExchange,
          _provider.getSigner(0)
        );
        const widrawAllNFTTx = await _poLotto.withdrawAllNFT().catch(err => {
          setLoadingStatus(false)
          console.log(err)
        })
        const result = await widrawAllNFTTx.wait()
        updateBalances();
        await fetchNFTData()
        setLoadingStatus(false)
        console.log(result)
      } catch (error) {
        setLoadingStatus(false)
      }
    }

    const WithdrawAllBtn = () => {
      if (segmentedType === 'ReadyNFT' && queueData.length > 0) {
        return (
          <Button type="primary" onClick={withdrawAllNft}>Withdraw All NFT</Button>
        )
      }
      return (
        <></>
      )
    }

    useEffect(() => {
      updateQueueOrNFT()
    }, [])

    return (
        <div>
          <div className="table-type">
            <Segmented options={['WaitingNFT', 'ReadyNFT']} onChange={(value) => {
                  if(value === 'WaitingNFT'){
                      fetchNFTWaitingData()
                  }else{
                      fetchNFTData()
                  }
                  setSegmentedType(value)
              }} />
            <WithdrawAllBtn />
          </div>
          <Table style={{ marginTop: 30 }} dataSource={queueData} columns={tableColums} rowKey="time" />
          <LoadingModal loadingStatus={loadingStatus} />
          <Spin spinning={loadingData} fullscreen />
        </div>
    )
})

export default WithdrawTable

import HomeTopMenu from "../../components/homePage/HomeTopMenu"
import Banner from "../../components/homePage/Banner"
import CardsContainer from "../../components/homePage/CradsContainer"
import KeyFeature from "../../components/homePage/KeyFeature"
import TotalValueDisplay from "../../components/homePage/TotalValueDisplay"
import AuditsText from "../../components/homePage/AuditsText"
import Update from "../../components/homePage/Update"
import Footer from "../../components/Footer/Footer"

export default function HomePage(){

    return (
        <div>
            <HomeTopMenu/>
            <Banner/>
            <CardsContainer/>
            <KeyFeature/>
            <TotalValueDisplay/>
            {/* <AuditsText/> */}
            {/* <Update/> */}
            <Footer/>
        </div>
    )
}
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Carousel } from "antd";
import step1Img from "../../images/step1.png";
import step2Img from "../../images/step2.png";
import step3Img from "../../images/step3.png";
import step4Img from "../../images/step4.png";
const Arrow1 = () => (
  <svg width="100" height="120">
    <line
      x1="50"
      y1="0"
      x2="50"
      y2="100"
      stroke="black"
      strokeWidth="2"
      markerEnd="url(#arrow)"
    />
    <defs>
      <marker
        id="arrow"
        markerWidth="10"
        markerHeight="10"
        refX="5"
        refY="3"
        orient="auto"
      >
        <path d="M0,0 L0,6 L9,3 z" fill="black" />
      </marker>
    </defs>
  </svg>
);
const Arrow2 = () => (
  <svg width="220" height="120">
    <line
      x1="200"
      y1="0"
      x2="25"
      y2="100"
      stroke="black"
      strokeWidth="2"
      markerEnd="url(#arrow)"
    />
    <defs>
      <marker
        id="arrow"
        markerWidth="10"
        markerHeight="10"
        refX="5"
        refY="3"
        orient="auto"
      >
        <path d="M0,0 L0,6 L9,3 z" fill="black" />
      </marker>
    </defs>
  </svg>
);
const Arrow3 = () => (
  <svg width="220" height="120">
    <line
      x1="25"
      y1="0"
      x2="200"
      y2="100"
      stroke="black"
      strokeWidth="2"
      markerEnd="url(#arrow)"
    />
    <defs>
      <marker
        id="arrow"
        markerWidth="10"
        markerHeight="10"
        refX="5"
        refY="3"
        orient="auto"
      >
        <path d="M0,0 L0,6 L9,3 z" fill="black" />
      </marker>
    </defs>
  </svg>
);
const Arrow4 = () => (
    <svg width="100" height="140">
      <line
        x1="50"
        y1="0"
        x2="50"
        y2="100"
        stroke="black"
        strokeWidth="2"
        markerEnd="url(#arrow1)"
      />
      <defs>
        <marker
          id="arrow1"
          markerWidth="10"
          markerHeight="10"
          refX="5"
          refY="3"
          orient="auto"
        >
          <path d="M0,0 L0,6 L9,3 z" fill="black" />
        </marker>
      </defs>
    </svg>
  );

export default function CardsContainer() {
  const { t } = useTranslation();

  return (
    <div className="main-container">
      <div className="cards-container container">
        <div className="single-card d-flex justify-content-center pt-5">
          <div className="single-card-container">
            <div className="card-img">
              <img src={step1Img} className="image" />
            </div>
            <div className="sub-title">{t("mint-token")}</div>
            <div className="comment">{t("mint-token=text")}</div>
          </div>
        </div>
        <div className="d-lg-flex justify-content-around mt-3 mb-3 d-none">
            <Arrow2 />
            <Arrow1 />
            <Arrow3 />
        </div>
        <div className="d-flex flex-column align-items-center">
            <div className="d-block mt-3 mb-3 d-lg-none ">
               <Arrow4 /> 
            </div>
            
        </div>
        <div className="single-card d-flex justify-content-between d-none d-lg-flex">
          <div className="single-card-container">
            <div className="card-img">
              <img src={step2Img} className="image" />
            </div>
            <div className="sub-title">{t("use-in-defi")}</div>
            <div className="comment">{t("use-in-defi-text")}</div>
          </div>
          <div className="single-card-container">
            <div className="card-img">
              <img src={step3Img} className="image" />
            </div>
            <div className="sub-title">{t("receive-yield")}</div>
            <div className="comment">{t("receive-yield-text")}</div>
          </div>
          <div className="single-card-container">
            <div className="card-img">
              <img src={step4Img} className="image" />
            </div>
            <div className="sub-title">{t("Bridge TD")}</div>
            <div className="comment">{t("bridge-td-text")}</div>
          </div>
        </div>

        <div className="d-lg-none single-card">
          <Carousel>
            <div className="d-flex justify-content-center">
                <div className="single-card-container justify-content-center">
                <div className="card-img">
                    <img src={step2Img} className="image" />
                </div>
                <div className="sub-title">{t("use-in-defi")}</div>
                <div className="comment">{t("use-in-defi-text")}</div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="single-card-container">
                <div className="card-img">
                    <img src={step3Img} className="image" />
                </div>
                <div className="sub-title">{t("receive-yield")}</div>
                <div className="comment">{t("receive-yield-text")}</div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="single-card-container">
                <div className="card-img">
                    <img src={step4Img} className="image" />
                </div>
                <div className="sub-title">{t("Bridge TD")}</div>
                <div className="comment">{t("bridge-td-text")}</div>
                </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState, useRef } from "react";
import { Image as AntdImage, Button } from "antd";
import NFTImage from "../../images/NFTImage.png";

export default function GenerateImageWord({ amount = 2.05, queueId = 1561, mintDate= '2024-5-21' }) {
  const imageurl = useRef();
  const [imageSrc, setImageSrc] = useState();
  const [visible, setVisible] = useState();
  useEffect(() => {
    // const canvas =  document.getElementById('imageref')
    // console.log(canvas)
    const canvas = imageurl.current;
    console.log(canvas);
    const context = canvas.getContext("2d");
    const img = new Image();
    img.src = NFTImage;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      //剪切位置(0,0),剪切尺寸(600,600),
      //放置位置(0,0),放置尺寸(100,100);
      context.drawImage(img, 0, 0);
      context.font = "bold 42px sans-serif";
      context.fillText("USDC", 30, 50);
      context.fillText(amount, 30, 100);

      //minted Date
      context.fillText("Minted Date:", 50, 540);
      context.fillText(mintDate, 340, 540);
      strokeRoundRect(context, 30, 500, 530, 60, 16)


      //queueId
      context.fillText("QueueId:", 50, 610);
      context.fillText(queueId, 250, 610);
      strokeRoundRect(context, 30, 570, 530, 60, 16)

      setImageSrc(convertCanvasToImage(canvas));
    };
  }, []);

  const convertCanvasToImage = (canvas) => {
    // var image = new Image();
    // image.src = canvas.toDataURL("image/png");
    // console.log(canvas.toDataURL("image/png"), 'sssssconvert')
    return canvas.toDataURL("image/png");
  };

  /**该方法用来绘制圆角矩形
   *@param cxt:canvas的上下文环境
   *@param x:左上角x轴坐标
   *@param y:左上角y轴坐标
   *@param width:矩形的宽度
   *@param height:矩形的高度
   *@param radius:圆的半径
   *@param lineWidth:线条粗细
   *@param strokeColor:线条颜色
   **/
  function strokeRoundRect(
    cxt,
    x,
    y,
    width,
    height,
    radius,
    /*optional*/ lineWidth,
    /*optional*/ strokeColor
  ) {
    //圆的直径必然要小于矩形的宽高
    if (2 * radius > width || 2 * radius > height) {
      return false;
    }

    cxt.save();
    cxt.translate(x, y);
    //绘制圆角矩形的各个边
    drawRoundRectPath(cxt, width, height, radius);
    cxt.lineWidth = lineWidth || 2; //若是给定了值就用给定的值否则给予默认值2
    cxt.strokeStyle = strokeColor || "#000";
    cxt.stroke();
    cxt.restore();
  }
  function drawRoundRectPath(cxt, width, height, radius) {
    cxt.beginPath(0);
    //从右下角顺时针绘制，弧度从0到1/2PI
    cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2);

    //矩形下边线
    cxt.lineTo(radius, height);

    //左下角圆弧，弧度从1/2PI到PI
    cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);

    //矩形左边线
    cxt.lineTo(0, radius);

    //左上角圆弧，弧度从PI到3/2PI
    cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);

    //上边线
    cxt.lineTo(width - radius, 0);

    //右上角圆弧
    cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);

    //右边线
    cxt.lineTo(width, height - radius);
    cxt.closePath();
  }

  return (
    <div>
      <canvas
        ref={imageurl}
        style={{ height: "300px", width: "200px", display: "none" }}
      ></canvas>
      <div>
        <Button type="primary" onClick={() => setVisible(true)}>Priview NFT</Button>
        <AntdImage
          src={imageSrc}
          style={{ display: "none" }}
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </div>
    </div>
  );
}

import { TopMenu } from "../../components/TopMenu"
import { useState, useEffect } from "react"
import { getAddress } from "../../utils/address"
import BridgeTD from "../../components/BridgeTD/BridgeTD.js"
import { BalanceProvider } from "../../hooks/useBalance"
import { Card } from "antd"
import WTDBalance from "../../components/wrapWTD/wTDBalance.js"
import useChainId from "../../hooks/useChainId.js"
import "./bridge.scss"

export default function BridgeWTD(){
    const [isConnect, setIsConnect] = useState(false)
    const chainId = useChainId()

    useEffect(() => {
        setIsConnect(getAddress()? true: false)
    }, [])
    //balances={this.props.balances} chainId={this.props.chainId} updateBalances={this.props.updateBalances}



    return (
        <BalanceProvider>
            <div style={{ backgroundColor: '#e2ccba', minHeight: '100vh'}}>
                <TopMenu isConnect={isConnect} />
                <div className="container  mt-2">
                    <WTDBalance/>
                    <div className="container d-flex justify-content-center">
                        <Card className="bridge-card"  title="Bridge" style={{ width: '100%', maxWidth: 600, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <BridgeTD chainId={chainId}/> 
                        </Card>
                    
                    </div>
                </div>
                
            </div>
        </BalanceProvider>
    )

}
import { Modal, Spin } from "antd"
import "./loading.scss"

export function LoadingModal({loadingStatus}){

    return(
        <div>
            <Modal
                open={loadingStatus}
                closable={false}
                footer={null}
            >   
                <div className="loading-content">
                    <Spin className="spin-loading" size="large"/>
                    <p className="loading-first-line">Waiting for comfirmation</p>
                    <p className="loading-second-line">Confirm this transaction in your wallet</p>
                    {/* <p>Some contents...</p> */}
                </div>
            </Modal>
        </div>
    )
}
import React, { Component } from "react";
import { Descriptions, Menu, Collapse,theme } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";
import { TopMenu } from "./TopMenu";
import { removeAddress, getAddress, setAddress } from "../utils/address";
import { withTranslation } from "react-i18next";
import "../index.css";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [],
      data1: [],
      data2: [],
      activeIndex: 0,
      isConnect: false,
    };
  }
  

  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  componentDidMount() {
    this.getInfo();
    this.setState({ isConnect: getAddress() ? true : false });
  }

  getInfo = async () => {
    const formatData = (rawData, rawData1) => {
      return rawData.map((item) => {
        let timestamp = 0;
        let amount = 0;
        let npv = 0;
        let res = rawData1.some((item2) => {
          return item2[0] == item[0];
        });
        if (res) {
          rawData1.forEach((item2) => {
            if (item2[0] == item[0]) {
              timestamp = new Date(item[0] * 1000)
                .toISOString()
                .replace("T", " ")
                .replace(/\.\d+Z$/, "");
              amount = (item[1] + item2[1]) / 1e18;
              npv = (item[2] + item2[2]) / 1e18;
            }
          });
        } else {
          timestamp = new Date(item[0] * 1000)
            .toISOString()
            .replace("T", " ")
            .replace(/\.\d+Z$/, "");
          amount = item[1] / 1e18;
          npv = item[2] / 1e18;
        }
        // const timestamp = new Date(item[0] * 1000).toISOString().replace('T', ' ').replace(/\.\d+Z$/, '');
        // const amount = item[1] / 1e18;
        // const npv = item[2] / 1e18;
        let profit = npv - amount;
        return { timestamp, amount, npv, profit };
      });
    };
    const formatSingleData = (rawData) => {
      return rawData.map((item) => {
        const timestamp = new Date(item[0] * 1000)
          .toISOString()
          .replace("T", " ")
          .replace(/\.\d+Z$/, "");
        const amount = item[1] / 1e18;
        const npv = item[2] / 1e18;
        return { timestamp, amount, npv };
      });
    };

    try {
      const response = await fetch("https://tizi.money/login/api/get-info");
      const response1 = await fetch("https://tizi.money/login/api/get-kava");
      const response2 = await fetch("https://tizi.money/login/api/get-cronos");
      const data = await response.json();
      const rawData1 = await response1.json();
      const rawData2 = await response2.json();
      const formattedTotalData = formatData(rawData2, rawData1);
      const formattedData1 = formatSingleData(rawData1);
      const formattedData2 = formatSingleData(rawData2);
      this.setState({
        info: data,
        totalData: formattedTotalData,
        data1: formattedData1,
        data2: formattedData2,
      });
    } catch (error) {
      console.error("Error fetching info:", error);
    }
  };

  _disConnectAccount() {
    removeAddress();
    this.setState({ isConnect: false });
    // window.ethereum.removeListener("accountsChanged", (s) => {

    // })
    // window.ethereum.removeListener("chainChanged", (s) => {

    // })
  }

  async _connectWallet() {
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    this.setState({ isConnect: true });
    setAddress(selectedAddress);
    // localStorage.setItem("selectedAddress", selectedAddress);
  }

  render() {
    const { info, data1, data2, totalData } = this.state;
    console.log("22", info[0]);
    console.log("22data1", data1);
    console.log("22data2", data2);
    // console.log('22', info)
    const data01 = [
      { name: "Mare Finance", value: data1[1]?.amount },
      { name: "CRO", value: data2[6]?.amount },
    ];
    const panelStyle = {
      marginBottom: 24,
      background: 'rgba(0, 0, 0, 0.02)',
      borderRadius: '8px',
      border: 'none',
    };
    return (
      <>
        <TopMenu
          disConnectAccount={() => this._disConnectAccount()}
          connectWallet={() => this._connectWallet()}
          isConnect={this.state.isConnect}
        />

        <div className="data">
          <h4>{this.props.t('Strategy')}</h4>
          <div style={{margin: '20px 0'}}>
            {info.map((item, index) => (
              <Collapse
                style={{
                  background: 'rgba(0, 0, 0, 0.02)',
                }}
                bordered={false}
                items={[
                  {
                    key: index,
                    label: index == 0 ? "Kava" : "Cronos",
                    children: (
                      <Collapse
                        key={index}
                        ghost
                        items={[
                          {
                            key: index,
                            label: item.name,
                            
                            children: (
                              <Descriptions column={1} bordered>
                                <Descriptions.Item label="Contract">
                                  {item.contract}
                                </Descriptions.Item>
                                <Descriptions.Item label="Description">
                                  {item.desc}
                                </Descriptions.Item>
                                <Descriptions.Item label="Risks">
                                  {item.risks.map((risk, riskIndex) => (
                                    <div key={riskIndex}>{risk}</div>
                                  ))}
                                </Descriptions.Item>
                                <Descriptions.Item label="URL">
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.url}
                                  </a>
                                </Descriptions.Item>
                              </Descriptions>
                            ),
                          },
                        ]}
                      />
                    ),
                    style: panelStyle,
                  },
                ]}
              />
              // <div key={index} className="desc">
              //   <Collapse
              //     ghost
              //     items={[
              //       {
              //         key: "1",
              //         label: "This is default size panel header",
              //         children: (
              //           <Collapse
              //             ghost
              //             items={[
              //               {
              //                 key: "1",
              //                 label: "This is default size panel header",
              //                 children: (
              //                   <Descriptions
              //                     title={item.name}
              //                     column={1}
              //                     bordered
              //                   >
              //                     <Descriptions.Item label="Contract">
              //                       {item.contract}
              //                     </Descriptions.Item>
              //                     <Descriptions.Item label="Description">
              //                       {item.desc}
              //                     </Descriptions.Item>
              //                     <Descriptions.Item label="Risks">
              //                       {item.risks.map((risk, riskIndex) => (
              //                         <div key={riskIndex}>{risk}</div>
              //                       ))}
              //                     </Descriptions.Item>
              //                     <Descriptions.Item label="URL">
              //                       <a
              //                         href={item.url}
              //                         target="_blank"
              //                         rel="noopener noreferrer"
              //                       >
              //                         {item.url}
              //                       </a>
              //                     </Descriptions.Item>
              //                   </Descriptions>
              //                 ),
              //               },
              //             ]}
              //           />
              //         ),
              //       },
              //     ]}
              //   />
              //   <Descriptions title={item.name} column={1} bordered>
              //     <Descriptions.Item label="Contract">
              //       {item.contract}
              //     </Descriptions.Item>
              //     <Descriptions.Item label="Description">
              //       {item.desc}
              //     </Descriptions.Item>
              //     <Descriptions.Item label="Risks">
              //       {item.risks.map((risk, riskIndex) => (
              //         <div key={riskIndex}>{risk}</div>
              //       ))}
              //     </Descriptions.Item>
              //     <Descriptions.Item label="URL">
              //       <a href={item.url} target="_blank" rel="noopener noreferrer">
              //         {item.url}
              //       </a>
              //     </Descriptions.Item>
              //   </Descriptions>
              // </div>
            ))}
          </div>

          <div>
            <h4>{this.props.t('Total-profit')}</h4>
          </div>
          <LineChart width={800} height={400} data={totalData} className="line">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" />
            <YAxis />
            <Tooltip />
            <Legend
              payload={[
                // { value: 'amount', type: 'line', color: '#8884d8', name: 'Amount' },
                // { value: 'npv', type: 'line', color: '#82ca9d', name: 'NPV (Net Present Value)' }
                {
                  value: "profit",
                  type: "line",
                  color: "#82ca9d",
                  name: "profit",
                },
              ]}
            />
            {/* <Line type="monotone" dataKey="amount" stroke="#8884d8" /> */}
            {/* <Line type="monotone" dataKey="npv" stroke="#82ca9d" /> */}
            <Line type="monotone" dataKey="profit" stroke="#82ca9d" />
          </LineChart>

          {/* <ResponsiveContainer width={730} height={250}> */}
          <div>
            <h4>{this.props.t('Strategy-distribution')}</h4>
          </div>
          <PieChart width={730} height={250}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={data01}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
            />
          </PieChart>
          {/* </ResponsiveContainer> */}
          {/* <PieChart width={730} height={250}>
          <Tooltip />
          <Legend verticalAlign="bottom" height={36}/>
          <Pie data={data01} dataKey="value" nameKey="name" label cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
          <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d"  />
        </PieChart> */}
        </div>
      </>
    );
  }
}

export default withTranslation()(Data);

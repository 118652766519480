import { useState, useEffect } from "react";
import {
    PieChart,
    Pie,
    Sector,
    Legend,
    Cell,
    ResponsiveContainer
  } from "recharts";
import { chainHasStrategy } from "../../utils/config";
export default function ActivePieChart({data}){

    const [activeIndex, setActiveIndex] = useState(1)
    const [chartSize, setChartSize] = useState({ width: 800, height: 400, outerRadius: 100, innerRadius:60 });

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          startAngle,
          endAngle,
          fill,
          payload,
          percent,
          value,
          name
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        
        if (chartSize.width > 500) {
          return (
            <g key={props.name +100 }>
              <text key={props.name +1 } x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
                {payload.name}
              </text>
              <text key={props.name +2} x={cx} y={cy} dy={18} textAnchor="middle" fill={fill}>
                {`${(percent * 100).toFixed(2)}%`}
              </text>
              <Sector
                cx={cx}
                cy={cy}
                key={Date.now()}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 4}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={activeColor[fill]}
              />
              <Sector
                cx={cx}
                cy={cy}
                key={Date.now() +1 }
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
              />
              <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
                key={props.name}
              />
              <circle key={props.name + 3} cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
              <text
                key={props.name + 4}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
              >{`${name}`}</text>
              <text
                key={props.name + 5}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
              >
                {`${(percent * 100).toFixed(2)}%`}
              </text>
            </g>
          );
        }
      
        return (
          <g key={props.name + 1000 }>
            <text key={props.name + 2000 } x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
                {payload.name}
              </text>
              <text key={props.name + 3000 } x={cx} y={cy} dy={18} textAnchor="middle" fill={fill}>
                {`${(percent * 100).toFixed(2)}%`}
              </text>
            <Sector
              key={Date.now() +2 }
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius +4 }
              startAngle={startAngle}
              endAngle={endAngle}
              fill={activeColor[fill]}
            />
            {/* <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <text
              x={ex - (cos >= 0 ? 1 : -1) * 36}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
            >{`${name}`}</text>
            <text
              x={ex - (cos >= 0 ? 1 : -1) * 30}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#999"
            >
              {`(~${value.toFixed(2)})`}
            </text> */}
          </g>
        );
    };

    // const data = [
    //     { name: "Base", value: 58.44 },
    //     { name: "Mantle", value: 20.35 },
    //     { name: "OP", value: 14.35 },
    // ];

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };
    
    // ['#0088FE', '#00C49F']
    const COLORS = {
      base: '#0088FE',
      optimism: '#00C49F',
      arbitrum: '#ce6872'
    };
    //['#66b8fe', '#66dcc5']
    const activeColor = {
      '#0088FE': '#1994fe',
      '#00C49F': '#19caa9',
      "#ce6872": '#d37780'
    }
    const capitalizeFirstLetter = (word) => {
      if (typeof word !== 'string' || word.length === 0) {
          return '';
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

    const CustomLegend = ({payload}) => {
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
        {
          chainHasStrategy.map((entry, index) => (
            <div key={`item-${index}`} style={{display: 'flex', alignItems: 'center', marginRight: '30px'}}>
              <div style={{height: '8px', width: '8px', backgroundColor: COLORS[entry], marginRight: '4px'}}></div>
              <div>{capitalizeFirstLetter(entry)}</div>
            </div>
          ))
        }
      </div>
      )
    }

    useEffect(() => {
      const handleResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        let outerRadius = Math.min(width, height) / 4; // 根据窗口大小动态调整外半径
        let innerRadius = Math.min(width, height) / 6; // 根据窗口大小动态调整外半径
        if (window.innerWidth > 500) {
          outerRadius = 80
          innerRadius = 60
        }
        setChartSize({ width, height, outerRadius, innerRadius });
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // 初始化
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <PieChart width={730} height={250}>
                <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={chartSize.innerRadius}
                outerRadius={chartSize.outerRadius}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} style={{outline: 'none'}} fill={COLORS[entry.chain]} />
                  ))}
                  {/* <LabelList dataKey="name" position="insideTop"  /> */}
                </Pie>
                <Legend content={<CustomLegend />}/>
            </PieChart>
        </ResponsiveContainer>
    )
}
import { useState, useEffect } from "react";
import {
    PieChart,
    Pie,
    Sector,
    Legend,
    Cell,
    ResponsiveContainer
  } from "recharts";
import { chainHasStrategy } from "../../utils/config";
export default function ActiveTokenInfoPieChart({data}){

    const [activeIndex, setActiveIndex] = useState(1)
    const [chartSize, setChartSize] = useState({ width: 800, height: 400, outerRadius: 100, innerRadius:60 });

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          startAngle,
          endAngle,
          fill,
          payload,
          percent,
          amount,
          value,
          name
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        
        if (chartSize.width > 500) {
          return (
            <g key={props.name +100 }>
              <text key={props.name +1 } x={cx} y={cy} dy={-5} textAnchor="middle" fill={fill}>
                {payload.name}
              </text>
              <text key={props.name +2} x={cx} y={cy} dy={10} textAnchor="middle" fill={fill}>
                {`${formatNumber(value)}`}
              </text>
              <text key={props.name +2} x={cx} y={cy} dy={25} textAnchor="middle" fill={fill}>
                {`${(percent * 100).toFixed(2)}%`}
              </text>
              <Sector
                cx={cx}
                cy={cy}
                key={`sector-${props.name}-${startAngle}-${endAngle}-1`}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 4}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={activeColor[fill]}
              />
              <Sector
                cx={cx}
                cy={cy}
                key={`sector-${props.name}-${startAngle}-${endAngle}-2`}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
              />
              <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
                key={props.name}
              />
              <circle key={props.name + 3} cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
              <text
                key={props.name + 4}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
              >{`${name}`}</text>
              <text
                key={props.name + 9}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
              >
                {`${(percent * 100).toFixed(2)}%`}
              </text>
            </g>
          );
        }
      
        return (
          <g key={props.name + 1000 }>
            <text key={props.name +1 } x={cx} y={cy} dy={-5} textAnchor="middle" fill={fill}>
                {payload.name}
              </text>
              <text key={props.name +2} x={cx} y={cy} dy={10} textAnchor="middle" fill={fill}>
                {`${formatNumber(value)}`}
              </text>
              <text key={props.name +2} x={cx} y={cy} dy={25} textAnchor="middle" fill={fill}>
                {`${(percent * 100).toFixed(2)}%`}
              </text>
            <Sector
              key={Date.now() +2 }
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius +4 }
              startAngle={startAngle}
              endAngle={endAngle}
              fill={activeColor[fill]}
            />
            {/* <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <text
              x={ex - (cos >= 0 ? 1 : -1) * 36}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
            >{`${name}`}</text>
            <text
              x={ex - (cos >= 0 ? 1 : -1) * 30}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#999"
            >
              {`(~${value.toFixed(2)})`}
            </text> */}
          </g>
        );
    };

    // const data = [
    //     { name: "Base", value: 58.44 },
    //     { name: "Mantle", value: 20.35 },
    //     { name: "OP", value: 14.35 },
    // ];

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };
    
    // ['#0088FE', '#00C49F']
    // const COLORS = {
    //   base: '#0088FE',
    //   optimism: '#00C49F',
    //   arbitrum: '#ce6872'
    // };
    //#8f9a7a
    const COLORS = [
      '#0088FE','#00C49F', '#ce6872', '#8f9a7a', '#b1b2a4', '#cc0137', '#ccc08a', '#ce6872', '#00C49F'
    ]
    //['#66b8fe', '#66dcc5']
    const activeColor = {
      '#0088FE': '#1994fe',
      '#00C49F': '#19caa9',
      "#ce6872": '#d37780',
      '#8f9a7a': '#9aa487',
      '#b1b2a4': '#b9baad',
      '#cc0137': '#d6345f',
      '#ccc08a': '#d6cda1'
    }

    const CustomLegend = ({payload}) => {
      return (
        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap',}}>
        {
          data.map((entry, index) => entry.value? (
            <div key={`item-${index}`} style={{display: 'flex', alignItems: 'center', marginRight: '30px', cursor: 'pointer'}} onClick={() => setActiveIndex(index)}>
              <div style={{height: '8px', width: '8px', backgroundColor: COLORS[index], marginRight: '4px'}}></div>
              <div>{entry.name}</div>
            </div>
          ) : '')
        }
      </div>
      )
    }

    function formatNumber(num) {
      if (num >= 1) {
        return num.toFixed(2)
      }
    // 将数字转换为字符串
    let numStr = num.toString();
    
    // 查找小数点的位置
    let decimalIndex = numStr.indexOf('.');
    
    // 获取小数部分
    let decimalPart = numStr.substring(decimalIndex + 1);
    
    // 计算小数点后连续的零的数量
    let zeroCount = 0;
    for (let i = 0; i < decimalPart.length; i++) {
        if (decimalPart[i] === '0') {
            zeroCount++;
        } else {
            break;
        }
    }
    
    // 如果没有找到非零数字，直接返回原字符串
    if (zeroCount === 0) return Number(numStr).toFixed(2);
    if (zeroCount === decimalPart.length) return numStr;
    
    // 确定下标值
    let subscript = zeroCount;
    
    // Unicode下标字符映射
    const subscriptChars = {
        1: '₁',
        2: '₂',
        3: '₃',
        4: '₄',
        5: '₅',
        6: '₆',
        7: '₇',
        8: '₈',
        9: '₉'
    };
    
    // 提取第一个非零数字及其后的两位非零数字
    let nonZeroDigits = [];
    for (let i = zeroCount; i < decimalPart.length; i++) {
        if (decimalPart[i] !== '0' && nonZeroDigits.length < 3) {
            nonZeroDigits.push(decimalPart[i]);
        }
    }
    
    // 构建新的字符串
    let newDecimalPart = `0${subscriptChars[subscript]}${nonZeroDigits.slice(0, 2).join('')}`;
    
    // 返回最终结果
    return `${numStr.substring(0, decimalIndex + 1)}${newDecimalPart}`;
}


    useEffect(() => {
      const handleResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        let outerRadius = Math.min(width, height) / 4; // 根据窗口大小动态调整外半径
        let innerRadius = Math.min(width, height) / 6; // 根据窗口大小动态调整外半径
        if (window.innerWidth > 500) {
          outerRadius = 80
          innerRadius = 60
        }
        setChartSize({ width, height, outerRadius, innerRadius });
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // 初始化
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <PieChart width={730} height={250}>
                <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={chartSize.innerRadius}
                outerRadius={chartSize.outerRadius}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}-${entry.name}`} style={{outline: 'none'}} fill={COLORS[index]} />
                  ))}
                  {/* <LabelList dataKey="name" position="insideTop"  /> */}
                </Pie>
                <Legend content={<CustomLegend />}/>
            </PieChart>
        </ResponsiveContainer>
    )
}
import { useState } from "react"
import { InputNumber, Button } from "antd"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"
import contractAddress from "../../../contracts/contract-address-last.json"
import PoLottoExchange from "../../../contracts/PoLottoExchangeABI.json"

export default function WithdrawFormOld({bookButton, balances, exchangeTokens}){
    const {t} = useTranslation()

    const [withdrawUsdcAmount, setWithdrawUsdcAmount] = useState()
    const [withdrawTdAmount, setWithdrawTdAmount] = useState()

    const handleInputChange = async(value) => {
        console.log(value)
        setWithdrawTdAmount(value)
        const rate = await getRate()
        setWithdrawUsdcAmount(value * rate)
    }

    const getRate = async() => {
      try {
        const _provider = new ethers.providers.Web3Provider(window.ethereum);
        const _poLotto = new ethers.Contract(
          contractAddress.Contract[0].address,
          PoLottoExchange,
          _provider.getSigner()
        );
        const rate = await _provider.DDExchangeRate()
        return ethers.utils.formatUnits(rate.toString(), 5) 
      } catch (error) {
        return 0.996
      }
    }

    const handleWithdrawClick = () => {
        exchangeTokens(withdrawTdAmount, 'Book')
    }

    const setMaxValue = async() => {
        setWithdrawTdAmount(balances)
        const rate = await getRate()
        setWithdrawUsdcAmount(balances * rate)
    }

    return (
        <div style={{ marginLeft: '5%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
              From
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputNumber
                  addonAfter="TD"
                  style={{
                    width: '100%',
                    maxWidth: 400,
                  }}
                  value={withdrawTdAmount}
                  min="0"
                  max={balances? balances['TDToken']: 0}
                  step="0.000000000000000001"
                  onChange={handleInputChange}
                  stringMode
                  precision={18}
                />
                <Button style={{ marginLeft: 15 }} size="small" type="primary" onClick={() => setMaxValue()} >Max</Button>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              To
              <InputNumber
                addonAfter="USDC"
                style={{
                  width: '100%',
                  maxWidth: 400,
                }}
                value={withdrawUsdcAmount}
                stringMode
                disabled
              />
            </div>
            <Button style={{ marginTop: 35, width: '100%',maxWidth: 400, }} type="primary" onClick={handleWithdrawClick} loading={!bookButton}>{bookButton?t('Withdraw'):t('Waiting')}</Button>
          </div>
    )
}
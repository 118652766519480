import { useEffect, useState } from "react"
import { ethers } from "ethers"
import contractAddress from "../../contracts/contract-address.json"
import RebaseAbi from "../../contracts/RebaseABI.json"
import TDTokenAbi from "../../contracts/TDToken.json"
import { chainRpc, chainHasStrategy, stragetyCoin, chainIdNum } from "../../utils/config"

export default function RebaseTime() {

    const [rebaseTime, setRebaseTime] = useState('')

    const getRebaseTime = async() => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
            // const providers = new ethers.providers.Web3Provider(window.ethereum)
            const rebaseControct = new ethers.Contract(
                contractAddress.TDToken.base,
                TDTokenAbi,
                // providers
                provider
            )
            const time = await rebaseControct.lastRebaseTime()
            if (parseInt(time)) {
                const dateTime = new Date(parseInt(time) * 1000)
                const formatTime = dateTime.toLocaleString()
                setRebaseTime(formatTime)
            }else{
                setRebaseTime('null')
            }
            console.log(parseInt(time))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getRebaseTime()
    }, [])

    return (
        <div style={{marginTop: '40px'}}>
            Last Rebase Time: {rebaseTime}
        </div>
    )
}
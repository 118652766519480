import React from "react";
import { useTranslation } from "react-i18next";

export function NoWalletDetected() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-6 p-4 text-center">
          <p>
            {t('detect-text')} <br />
            {t('detect-text-after')}{" "}
            <a href="http://metamask.io" target="_blank" rel="noopener noreferrer">
              MetaMask
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

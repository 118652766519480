import i18n from 'i18next'
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import zhCNTrans from "./locales/zh-CN/resources.json";
import enUSTrans from "./locales/en-US/resources.json";

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    // 后面切换需要使用此处定义的key
    "zh-CN": {
      translation: zhCNTrans,
    },
    "en-US": {
      translation: enUSTrans,
    },
  },
  // lng: "zh-CN",
  fallbackLng: "en-US",
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
